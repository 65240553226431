export interface CustomEndpoint {
  id: string;
  dbId?: string;
  namespaceId: string;
  name: string;
  suffix: string;
  isTransferable: boolean;
  isRevertible: boolean;
  sourceDbId?: string;
}

export enum CustomEndpointStatus {
  // ACTIVE, but no assigned instance
  DISCONNECTED = 'DISCONNECTED',
  // Unrecognized status:
  UNKNOWN = 'UNKNOWN',
  // Traffic Config API States:
  SUBMITTED = 'SUBMITTED',
  ACTIVE = 'ACTIVE',
  DELETED = 'DELETED',
  ERROR = 'ERROR',
}

export interface CustomEndpointDetails {
  id: string;
  dbId?: string;
  namespaceId: string;
  name: string;
  suffix: string;
  isTransferable: boolean;
  isRevertible: boolean;
  sourceDbId?: string;
  status: CustomEndpointStatus;
}

export interface InternalCustomEndpointDetails {
  id: string;
  dbId?: string;
  namespaceId: string;
  name: string;
  suffix: string;
  updatedAt: Date;
  deletedAt?: Date;
  sourceDbId?: string;
  reportedDbid: string;
  reportedOrchestra: string;
  reportedFQDN: string;
  reportedStatus: string;
}

// API

export interface CustomEndpointResponse {
  id: string;
  dbid?: string;
  namespace_id: string;
  name: string;
  suffix: string;
  is_transferable: boolean;
  is_revertible: boolean;
  source_dbid?: string;
}

export interface CustomEndpointDetailsResponse {
  id: string;
  dbid?: string;
  namespace_id: string;
  name: string;
  suffix: string;
  is_transferable: boolean;
  is_revertible: boolean;
  source_dbid?: string;
  status: CustomEndpointStatus;
}

export interface InternalCustomEndpointDetailsResponse {
  id: string;
  dbid?: string;
  namespace_id: string;
  name: string;
  suffix: string;
  updated_at: Date;
  deleted_at?: Date;
  source_dbid?: string;
  reported_dbid: string;
  reported_orchestra: string;
  reported_fqdn: string;
  reported_status: string;
}
