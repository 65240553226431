import React from 'react';
import { Database, gibibytesStringToInt } from 'entities/database';
import { ConfigureDatabaseFormData } from './form-data';
import { NewTabLink, Switch, Tip, Typography } from 'foundation';

type GdsPluginUpdateProps = {
  data: ConfigureDatabaseFormData;
  onChange: (data: ConfigureDatabaseFormData) => void;
  database: Database;
};

export const GdsPluginForm = ({ data, onChange, database }: GdsPluginUpdateProps) => {
  const handleGdsPluginChange = (value: boolean) => {
    onChange({ ...data, gdsPlugin: value });
  };

  if (!database.AvailableActions.gds_plugin.enabled) {
    return <p>Graph Analytics unavailable: {database.AvailableActions.gds_plugin.message}</p>;
  }

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-2">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Typography variant="subheading-medium">Graph Analytics</Typography>
        <Typography variant="body-medium" as="div" className="tw-text-palette-neutral-text-weak">
          Enable Graph Analytics libraries on this instance. See{' '}
          <NewTabLink href="https://neo4j.com/docs/aura/graph-analytics/">documentation</NewTabLink>{' '}
          for details.
        </Typography>
      </div>
      <div>
        <Tip
          isDisabled={
            (data.size ? gibibytesStringToInt(data.size.memory) >= 4 : true) &&
            database.DesiredSettings.Version === '5'
          }
        >
          <Tip.Trigger>
            <Switch
              aria-label="Toggle to install GDS plugin on instance"
              checked={data.gdsPlugin}
              onChange={e => handleGdsPluginChange(e.target.checked)}
              disabled={
                (data.size ? gibibytesStringToInt(data.size.memory) < 4 : false) ||
                database.DesiredSettings.Version !== '5'
              }
            />
          </Tip.Trigger>
          <Tip.Content isPortaled={false}>
            4GB memory is required for the gds plugin configuration
          </Tip.Content>
        </Tip>
      </div>
    </div>
  );
};

type VectorOptimizedUpdateProps = {
  data: ConfigureDatabaseFormData;
  onChange: (data: ConfigureDatabaseFormData) => void;
  database: Database;
};

export const VectorOptimizedForm = ({ data, onChange, database }: VectorOptimizedUpdateProps) => {
  const handleVectorOptimizeChange = (value: boolean) => {
    onChange({ ...data, vectorOptimized: value });
  };

  if (!database.AvailableActions.vector_optimized.enabled) {
    return (
      <p>Vector optimization unavailable: {database.AvailableActions.vector_optimized.message}</p>
    );
  }

  return (
    <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-py-2">
      <div className="tw-flex tw-flex-col tw-gap-2">
        <Typography variant="subheading-medium">Vector-optimized configuration</Typography>
        <Typography variant="body-medium" as="div" className="tw-text-palette-neutral-text-weak">
          Reserve memory for vector indexes to ensure optimal performance. Selecting this can have
          an impact on graph operation performance
        </Typography>
      </div>
      <div>
        <Tip
          isDisabled={
            (data.size ? gibibytesStringToInt(data.size.memory) >= 4 : true) &&
            database.DesiredSettings.Version === '5'
          }
        >
          <Tip.Trigger>
            <Switch
              aria-label="Toggle on Allocate vector index memory"
              checked={data.vectorOptimized}
              onChange={e => handleVectorOptimizeChange(e.target.checked)}
              disabled={
                (data.size ? gibibytesStringToInt(data.size.memory) < 4 : false) ||
                database.DesiredSettings.Version !== '5'
              }
            />
          </Tip.Trigger>
          <Tip.Content isPortaled={false}>
            4GB memory is required for the vector index configuration
          </Tip.Content>
        </Tip>
      </div>
    </div>
  );
};
