import React, { useState } from 'react';
import { Tabs, Page } from 'foundation';
import { usePermissions } from 'store';
import { Action } from 'types/user';
import SearchByURLForm from './search-by-url-form';
import SearchByDbForm from './search-by-db-form';
import SearchByNamespaceForm from './search-by-namespace-form';
import SearchByIdForm from './search-by-id-form';

enum TabId {
  Id = 'id',
  Url = 'url',
  DbId = 'dbid',
  NamespaceId = 'namespace-id',
}

export const LookupCustomEndpointsPage = () => {
  const permission = usePermissions();
  const canReadCustomEndpointss = permission.allow(Action.READ, 'internal/custom-endpoints');
  const [selectedTabId, setSelectedTabId] = useState(TabId.Id);

  return (
    <Page fullWidth>
      {canReadCustomEndpointss ? (
        <>
          <h5 className="tw-mb-4" data-testid="lookup-tenant-page-header">
            Lookup Custom Endpoint(s)
          </h5>

          <Tabs<TabId> className="tw-mb-8" value={selectedTabId} onChange={setSelectedTabId}>
            <Tabs.Tab tabId={TabId.Id}>ID</Tabs.Tab>
            <Tabs.Tab tabId={TabId.Url}>URL</Tabs.Tab>
            <Tabs.Tab tabId={TabId.DbId}>Instance</Tabs.Tab>
            <Tabs.Tab tabId={TabId.NamespaceId}>Project</Tabs.Tab>
          </Tabs>

          <Tabs.TabPanel tabId={TabId.Id} value={selectedTabId} data-testid="search-id-page">
            <SearchByIdForm />
          </Tabs.TabPanel>
          <Tabs.TabPanel tabId={TabId.Url} value={selectedTabId} data-testid="search-url-page">
            <SearchByURLForm />
          </Tabs.TabPanel>
          <Tabs.TabPanel tabId={TabId.DbId} value={selectedTabId} data-testid="search-dbid-page">
            <SearchByDbForm />
          </Tabs.TabPanel>
          <Tabs.TabPanel
            tabId={TabId.NamespaceId}
            value={selectedTabId}
            data-testid="search-namespace-id-page"
          >
            <SearchByNamespaceForm />
          </Tabs.TabPanel>
        </>
      ) : (
        <h5 data-testid="authorize-users-access-denied">Access Denied</h5>
      )}
    </Page>
  );
};

export default LookupCustomEndpointsPage;
