import formatDollars from 'utils/format-dollars';

import { Alert, IconButton, LoadingSpinner, TextInput, Tag } from 'foundation';
import Actions from 'actions';
import React, { useEffect, useState, SyntheticEvent } from 'react';
import ConsumptionFilterReportsMenu from './consumption-filter-reports-menu';
import { MagnifyingGlassIconOutline } from '@neo4j-ndl/react/icons';
import { ConsumptionReportsTable } from './consumption-reports-table';
import { BillingMethod, Tenant } from 'entities/tenant';
import { generateDefaultDateFilter } from './utils';
import { TransformedConsumption, Filter, DateFilter, Pagination, Sorting } from 'types/consumption';

const MINIMUM_SEARCH_LENGTH = 3;

export interface ConsumptionReportProps {
  tenant: Tenant;
  orb_reporting_cutoff_date: Date;
}

const ConsumptionReport = ({ tenant, orb_reporting_cutoff_date }: ConsumptionReportProps) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [pagination, setPagination] = useState<Pagination>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<Sorting>([
    {
      id: 'name',
      desc: false,
    },
  ]);
  const [consumption, setConsumption] = useState<TransformedConsumption>();
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState<Filter[] | DateFilter[]>([...generateDefaultDateFilter()]);
  const [isLoading, setIsLoading] = useState(true);
  const [requestPending, setRequestPending] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [outstandingAmount, setOutstandingAmount] = useState(0);

  const useDebounce = (value: string, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState(value);

    useEffect(() => {
      const handler = setTimeout(() => setDebouncedValue(value), delay);

      return () => clearTimeout(handler);
    }, [value, delay]);

    return debouncedValue;
  };

  const debouncedSearchFilter = useDebounce(searchFilter, 500);

  const getConsumptionByFilters = (
    filters: Filter[] | DateFilter[],
    pagination: Pagination,
    sorting: Sorting,
    searchFilter: string
  ) => {
    setRequestPending(true);
    setErrorMessage(null);

    Actions.consumption
      .fetch(tenant.id, filters, pagination, sorting, searchFilter)
      .then(filteredConsumption => {
        setConsumption({
          ...consumption,
          totalCost: filteredConsumption.totalCost,
          currency: filteredConsumption.currency,
          discount: filteredConsumption.discount || null,
          breakdown: filteredConsumption.breakdown,
          totalBreakdownCount: filteredConsumption.totalBreakdownCount,
          usagePeriodEnd: filteredConsumption.usagePeriodEnd,
          usagePeriodStart: filteredConsumption.usagePeriodStart,
        });
        let newOutstandingAmount = parseFloat(filteredConsumption.totalCost);

        if (filteredConsumption.discount) {
          newOutstandingAmount -= parseFloat(filteredConsumption.discount.dollarAmountOff);
        }
        setOutstandingAmount(newOutstandingAmount);
      })
      .catch(e => {
        setErrorMessage(e.message);
      })
      .finally(() => {
        setIsLoading(false);
        setRequestPending(false);
      });
  };

  useEffect(() => {
    getConsumptionByFilters(filters, pagination, sorting, searchFilter);
  }, [filters, pagination, sorting]);

  useEffect(() => {
    if (debouncedSearchFilter === null) return;
    if (requestPending) return;
    if (debouncedSearchFilter.length < MINIMUM_SEARCH_LENGTH && debouncedSearchFilter.length > 0)
      return;
    getConsumptionByFilters(filters, pagination, sorting, debouncedSearchFilter);
  }, [debouncedSearchFilter]);

  const openFiltering = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };

  if (isLoading) {
    return <LoadingSpinner expand className="tw-w-full" />;
  }

  if (!consumption.breakdown && !requestPending && !errorMessage) {
    return (
      <Alert
        type="info"
        name="notes"
        icon
        className="tw-my-4"
        data-testid="billing-usage-report-no-data"
      >
        There is no usage data for this month.
      </Alert>
    );
  }

  return (
    <div data-testid="billing-usage-report" className="tw-flex tw-flex-col tw-gap-y-1">
      <>
        <div className="tw-flex tw-justify-between">
          <div className="tw-flex tw-justify-content-start tw-items-align-start">
            <TextInput
              aria-label="Reports search"
              placeholder="Type at least 3 characters to search"
              leftIcon={<MagnifyingGlassIconOutline />}
              value={searchFilter}
              disabled={requestPending}
              onChange={value => setSearchFilter(value.target.value)}
              className="tw-pr-1"
            />
            <IconButton
              title="Filter Reports"
              data-testid="filter-reports"
              aria-label="Filter Reports"
              iconName="FunnelIconOutline"
              onClick={openFiltering}
              disabled={requestPending}
            />
            <ConsumptionFilterReportsMenu
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              requestPending={requestPending}
              filters={filters}
              onUpdate={(filters: Filter[] | DateFilter[]) => {
                setFilters([...filters]);
                setAnchorEl(null);
              }}
              onClose={() => {
                setAnchorEl(null);
              }}
              orb_reporting_cutoff_date={orb_reporting_cutoff_date}
            />
          </div>
          {!requestPending && tenant.billingMethod === BillingMethod.PREPAID && (
            <div className="tw-flex tw-items-center tw-gap-4">
              <div>
                <span className="n-body-medium tw-text-palette-neutral-text-weaker">
                  Total credits used:
                </span>
                <span className="n-label tw-text-palette-neutral-text-weaker tw-ml-1">
                  {consumption.totalCost}
                </span>
              </div>
            </div>
          )}
          {!requestPending && tenant.billingMethod !== BillingMethod.PREPAID && (
            <div
              className="tw-my-6 tw-ml-auto tw-grid tw-gap-x-6 tw-gap-y-2"
              style={{
                width: 'fit-content',
                gridTemplateColumns: 'max-content max-content',
              }}
            >
              {consumption?.discount && (
                <>
                  <div>
                    <span>Subtotal:</span>
                  </div>
                  <div className="tw-justify-self-end tw-font-mono" data-testid="subtotal-amount">
                    {formatDollars(consumption.totalCost)}
                  </div>
                  <div>
                    <div>
                      <div>Discount:</div>
                      <div
                        className="n-body-medium tw-text-palette-neutral-text-weaker"
                        data-testid="discount-promotion-name"
                      >
                        {consumption.discount.promotionName}
                      </div>
                    </div>
                  </div>
                  <div className="tw-justify-self-end tw-font-mono" data-testid="discount-amount">
                    - {formatDollars(consumption.discount.dollarAmountOff)}
                  </div>
                </>
              )}
              <div>
                {outstandingAmount < 0
                  ? 'In-credit balance so far this month:'
                  : 'Amount due so far this month:'}
              </div>
              <div
                className="tw-justify-self-end tw-font-mono tw-font-bold"
                data-testid="final-amount"
              >
                {formatDollars(Math.abs(outstandingAmount))}
              </div>
            </div>
          )}
        </div>
        {errorMessage && (
          <Alert
            className="tw-mt-2"
            type="danger"
            data-testid="filter-usage-error-message"
            description="There was an error on fetching results"
          />
        )}
        <div>
          {filters.map((filter, index) => (
            <Tag
              key={index}
              onRemove={() => {
                if (requestPending) return;
                setFilters(prevState => prevState.filter((_, i) => i !== index));
              }}
              removeable={true}
            >
              <span>{filter.label}</span>
            </Tag>
          ))}
        </div>
      </>

      {consumption?.breakdown && (
        <ConsumptionReportsTable
          breakdown={consumption.breakdown}
          totalBreakdownCount={consumption.totalBreakdownCount}
          isLoading={requestPending}
          searchFilter={debouncedSearchFilter}
          pagination={pagination}
          sorting={sorting}
          setSearchFilter={setSearchFilter}
          setPagination={setPagination}
          setSorting={setSorting}
        />
      )}
    </div>
  );
};

export default ConsumptionReport;
