import { useQuery, UseQueryResult } from 'react-query';
import ApiClient from 'remote/api-client';
import {
  CustomEndpoint,
  CustomEndpointDetails,
  CustomEndpointDetailsResponse,
  CustomEndpointResponse,
  CustomEndpointStatus,
  InternalCustomEndpointDetails,
  InternalCustomEndpointDetailsResponse,
} from 'types/custom-endpoints';

const transformCustomEndpointResponse = (response: CustomEndpointResponse): CustomEndpoint => ({
  id: response.id,
  dbId: response.dbid,
  namespaceId: response.namespace_id,
  name: response.name,
  suffix: response.suffix,
  isTransferable: response.is_transferable,
  isRevertible: response.is_revertible,
  sourceDbId: response.source_dbid,
});

const transformStatus = (status: CustomEndpointStatus, dbId?: string) => {
  if (status === CustomEndpointStatus.ACTIVE && !dbId) {
    return CustomEndpointStatus.DISCONNECTED;
  }

  return status;
};

const transformCustomEndpointDetailsResponse = (
  response: CustomEndpointDetailsResponse
): CustomEndpointDetails => ({
  id: response.id,
  dbId: response.dbid,
  namespaceId: response.namespace_id,
  name: response.name,
  suffix: response.suffix,
  isTransferable: response.is_transferable,
  isRevertible: response.is_revertible,
  sourceDbId: response.source_dbid,
  status: transformStatus(response.status, response.dbid),
});

const transformInternalCustomEndpointDetailsResponse = (
  response: InternalCustomEndpointDetailsResponse
): InternalCustomEndpointDetails => ({
  id: response.id,
  dbId: response.dbid,
  namespaceId: response.namespace_id,
  name: response.name,
  suffix: response.suffix,
  updatedAt: response.updated_at,
  deletedAt: response.deleted_at,
  sourceDbId: response.source_dbid,
  reportedDbid: response.reported_dbid,
  reportedOrchestra: response.reported_orchestra,
  reportedFQDN: response.reported_fqdn,
  reportedStatus: response.reported_status,
});

const create = ({ dbId, name }: { dbId: string; name: string }): Promise<CustomEndpoint> =>
  ApiClient.post('/custom-endpoints')
    .issue({ dbid: dbId, name: name })
    .ignoreAllErrors()
    .then(response => transformCustomEndpointResponse(response));

const update = (customEndpointId: string, dbId: string): Promise<CustomEndpoint> =>
  ApiClient.patch(`/custom-endpoints/${customEndpointId}`)
    .issue({ dbid: dbId })
    .ignoreAllErrors()
    .then(response => transformCustomEndpointResponse(response));

const listByDatabase = (dbId: string): Promise<CustomEndpoint[]> =>
  ApiClient.get(`/databases/${dbId}/custom-endpoints`)
    .ignoreAllErrors()
    .then(responses => responses.map(response => transformCustomEndpointResponse(response)));

const listByNamespace = (namespaceId: string): Promise<CustomEndpointDetails[]> =>
  ApiClient.get(`/namespaces/${namespaceId}/custom-endpoints`)
    .ignoreAllErrors()
    .then(responses => responses.map(response => transformCustomEndpointDetailsResponse(response)));

const internalGet = (customEndpointId: string): Promise<InternalCustomEndpointDetails> =>
  ApiClient.get(`/internal/custom-endpoints/${customEndpointId}`)
    .ignoreAllErrors()
    .then(response => transformInternalCustomEndpointDetailsResponse(response));

const internalListByQuery = ({
  url,
  dbId,
  namespaceId,
  includeDeleted = false,
}: {
  url?: string;
  dbId?: string;
  namespaceId?: string;
  includeDeleted?: boolean;
}): Promise<InternalCustomEndpointDetails[]> => {
  const query = `include_deleted=${includeDeleted ? 'true' : 'false'}${
    url ? '&url=' + encodeURIComponent(url) : ''
  }${dbId ? '&dbid=' + encodeURIComponent(dbId) : ''}${
    namespaceId ? '&namespace_id=' + encodeURIComponent(namespaceId) : ''
  }`;
  return ApiClient.get(`/internal/custom-endpoints?${query}`)
    .ignoreAllErrors()
    .then(responses =>
      responses.map(response => transformInternalCustomEndpointDetailsResponse(response))
    );
};

const destroy = (customEndpointId: string): Promise<void> =>
  ApiClient.delete(`/custom-endpoints/${customEndpointId}`)
    .ignoreAllErrors()
    .promise();

export const useListByDatabase = (
  dbId: string,
  options?: { skip: boolean }
): UseQueryResult<CustomEndpoint[]> => {
  const query = async () => {
    const endpoints = await listByDatabase(dbId);
    return endpoints;
  };

  return useQuery(`db-custom-endpoints-${dbId}`, query, {
    enabled: !options?.skip,
    refetchInterval: 10000,
  });
};

export const useListByNamespace = (
  namespaceId: string,
  options?: { skip: boolean }
): UseQueryResult<CustomEndpointDetails[]> => {
  const query = async () => {
    const endpoints = await listByNamespace(namespaceId);
    return endpoints;
  };

  return useQuery(`namespace-custom-endpoints-${namespaceId}`, query, {
    enabled: !options?.skip,
    refetchInterval: 10000,
  });
};

export const useInternalGet = (
  customEndpointId: string,
  options?: { skip: boolean }
): UseQueryResult<InternalCustomEndpointDetails> => {
  const query = async () => {
    const endpoint = await internalGet(customEndpointId);
    return endpoint;
  };

  return useQuery(`internal-custom-endpoint-${customEndpointId}`, query, {
    enabled: !options?.skip,
    refetchInterval: 10000,
  });
};

export default {
  create,
  update,
  destroy,
  internalListByQuery,
  internalGet,
};
