import React, { useState } from 'react';
import { FormInput, Button, Form, Alert } from 'foundation';
import Actions from 'actions';
import customEndpointResources from 'remote/resources/custom-endpoints';
import { ApiClientRequestError } from 'remote/api-client/api-client-error';

const SearchByIdForm = ({ ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);

  const handleQueryChange = ({ target: { value } }) => setQuery(value);

  const handleSearchSubmit = async () => {
    if (!query) return;

    setErrorMessage(null);
    setLoading(true);

    customEndpointResources
      .internalGet(query.trim())
      .then(() => Actions.navigate.replace(`#admin/custom-endpoints/${query.trim()}`))
      .catch(e => {
        if (e instanceof ApiClientRequestError) {
          setErrorMessage(e.responseMessage);
        } else {
          setErrorMessage(String(e));
        }
        setLoading(false);
      });
  };

  return (
    <div {...rest}>
      <p className="tw-mb-2">This form enables you to find a custom endpoint by its ID.</p>
      <p className="tw-mb-2">Only exact ID matches can be found.</p>

      <Form onSubmit={handleSearchSubmit}>
        <FormInput
          fluid
          value={query}
          onChange={handleQueryChange}
          label="ID"
          data-testid="lookup-custom-endpoint-by-id"
          helpText="The exact ID of the custom endpoint"
        />
        <Button
          className="tw-mt-8"
          type="submit"
          color="primary"
          loading={loading}
          disabled={!query}
          data-testid="lookup-custom-endpoint-by-id-button"
        >
          Submit
        </Button>
      </Form>

      {errorMessage && <Alert type="danger" className="tw-mt-2" description={errorMessage} />}
    </div>
  );
};

export default SearchByIdForm;
