import React, { useState } from 'react';
import {
  CopyTextToClipBoardButton,
  IconButton,
  Label,
  Link,
  LoadingSpinner,
  Page,
  Tip,
} from 'components/foundation';
import { useSession } from 'store';
import { useDatabaseQuery } from 'remote/resources/databases';
import promConfigTemplate, { metricsIntegrationApiVersion } from './prometheus-config-template';
import MetricsIntegrationConfigModal from './prometheus-config-modal';
import { Database, Tier } from 'entities/database';

export const formatAuraApiDomain = (
  environment: string | undefined,
  neo4jDomain: string | undefined
) =>
  `api${environment && environment !== 'production' ? '-' + environment : ''}${
    neo4jDomain ? '.' + neo4jDomain : ''
  }`;
export const formatCmiDomain = (environment: string | undefined, neo4jDomain: string | undefined) =>
  `customer-metrics-api${environment && environment !== 'production' ? '-' + environment : ''}${
    neo4jDomain ? '.' + neo4jDomain : ''
  }`;

const UrlsView = ({
  databases,
  tenantUrl,
  cmiDomain,
  currentTenantId,
  onConfigClick,
}: {
  databases: Database[];
  tenantUrl: string;
  cmiDomain: string;
  currentTenantId: string;
  onConfigClick: (dbId: string) => void;
}) => {
  return databases.length === 0 ? (
    <div className="tw-flex">
      <span className="n-body-large tw-text-palette-neutral-text-default">
        You have no eligible instances for metrics integration.
      </span>
    </div>
  ) : (
    <>
      <div className="tw-mb-4 tw-mt-2">
        <span className="n-body-large tw-text-palette-neutral-text-default">
          Endpoint URL for tenant:
        </span>
        <EndpointUrlWithActions url={tenantUrl} actionHandler={() => onConfigClick('')} />
      </div>

      <div className="tw-my-2">
        <span className="n-body-large tw-text-palette-neutral-text-default">
          Endpoint URL for instances:
        </span>
        {databases.map((db, i) => {
          const instanceUrl = `https://${cmiDomain}/api/${metricsIntegrationApiVersion}/${currentTenantId}/${db.DbId}/metrics`;

          return (
            <div className="tw-flex tw-mt-2" key={db.DbId + i}>
              <Label className="tw-mr-2" fill="outlined">
                {db.Name}
              </Label>
              <EndpointUrlWithActions
                url={instanceUrl}
                actionHandler={() => onConfigClick(db.DbId)}
              />
            </div>
          );
        })}
      </div>

      <div className="tw-my-4">
        <span className="n-body-large tw-text-palette-neutral-text-default">
          Endpoint authentication:
        </span>
        <p className="tw-text-palette-neutral-text-weaker">
          Accessing metric endpoints requires Aura API credentials. You can find and create them{' '}
          <Link href="#account" aria-label="Aura API credentials">
            here.
          </Link>
        </p>
      </div>
    </>
  );
};

const MetricIntegrationPage = () => {
  const session = useSession();
  const currentTenantId = session.currentTenant;
  const dbs = useDatabaseQuery(currentTenantId);
  const [prometheusConfigModal, setPrometheusConfigModal] = useState(false);
  const [promConfig, setPromConfig] = useState(null);
  const auraDomain = formatAuraApiDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);
  const cmiDomain = formatCmiDomain(process.env.ENVIRONMENT, process.env.NEO4J_DNS_DOMAIN);

  const onConfigClick = (dbId: string) => {
    const metricsPathParam = dbId ? currentTenantId + '/' + dbId : currentTenantId;
    setPromConfig(promConfigTemplate(metricsPathParam, auraDomain, cmiDomain));
    setPrometheusConfigModal(true);
  };

  const tenantUrl = `https://${cmiDomain}/api/${metricsIntegrationApiVersion}/${currentTenantId}/metrics`;

  return (
    <Page plain fullWidth>
      <div className="tw-flex tw-items-center">
        <h4 className="tw-mr-2">Metrics integration</h4>
        <div className="tw-flex">
          <Link href="https://neo4j.com/docs/aura/platform/metrics-integration/" newTab>
            Docs
          </Link>
        </div>
      </div>

      <div
        className="tw-rounded-2xl tw-bg-palette-neutral-bg-weak tw-shadow-raised tw-p-6 tw-my-4"
        data-testid="metrics-integration-urls"
      >
        {dbs.isLoading ? (
          <LoadingSpinner className="tw-mx-auto" />
        ) : dbs.data.length === 0 ? (
          <span className="n-body-large tw-text-palette-neutral-text-default">
            You have no running instances.
          </span>
        ) : (
          <UrlsView
            databases={dbs.data.filter(db =>
              [Tier.ENTERPRISE, Tier.MTE, Tier.AURA_DSE].includes(db.Tier)
            )}
            tenantUrl={tenantUrl}
            cmiDomain={cmiDomain}
            currentTenantId={currentTenantId}
            onConfigClick={onConfigClick}
          />
        )}
      </div>

      <MetricsIntegrationConfigModal
        prometheusConfig={promConfig}
        open={prometheusConfigModal}
        onClose={() => setPrometheusConfigModal(false)}
      />
    </Page>
  );
};

type EndpointUrlWithActionsProps = {
  url: string;
  actionHandler: () => void;
};

const EndpointUrlWithActions = ({ url, actionHandler }: EndpointUrlWithActionsProps) => {
  return (
    <div className="tw-flex">
      <span className="n-body-large tw-text-palette-neutral-text-weaker">{url}</span>
      <div className="tw-flex">
        <CopyTextToClipBoardButton
          iconButtonProps={{ size: 'small' }}
          text={url}
          className="tw-ml-1"
        />
        <Tip>
          <Tip.Trigger>
            <IconButton
              size="small"
              clean
              onClick={actionHandler}
              aria-label="Show Prometheus job configuration"
              data-testid="show-prometheus-job-config-button"
              iconName="CommandLineIconOutline"
              className="tw-ml-1"
            />
          </Tip.Trigger>
          <Tip.Content>
            Prometheus job configuration to retrieve metrics from the endpoint
          </Tip.Content>
        </Tip>
      </div>
    </div>
  );
};

export default MetricIntegrationPage;
