import { PricingPlanName } from 'entities/tenant';

export enum BillingModel {
  PAY_AS_YOU_GO = 'Pay As You Go',
  PREPAID_CONSUMPTION = 'Prepaid Consumption',
}

export enum BillingProcessor {
  DIRECT = 'Direct',
  AZURE_MARKETPLACE = 'Azure Marketplace',
  GCP_MARKETPLACE = 'GCP Marketplace',
  AWS_MARKETPLACE = 'AWS Marketplace',
}

export enum PaymentMethod {
  CREDIT_CARD = 'Credit Card',
  MARKETPLACE = 'Marketplace',
  BALANCE = 'Balance',
}

export enum BillingAccountStatus {
  CREATED = 'Created',
  ACTIVATED = 'Activated',
  ACTIVE_IN_USE = 'Active in Use',
  SUSPENDED = 'Suspended',
  DEACTIVATED = 'Deactivated',
}

export interface BillingAccount {
  id: string;
  name: string;
  billingModel: BillingModel;
  billingProcessor: BillingProcessor;
  organizationId: string;
  paymentMethod: PaymentMethod;
  pricingPlan: PricingPlanName;
  projectIds: string[];
  reason: string;
  status: BillingAccountStatus;
}
