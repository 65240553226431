import { Tenant } from 'entities/tenant';
import { Button } from 'foundation';
import React, { useState } from 'react';
import ConsumptionReport from './consumption-report';
import OldUsageReport from './old-usage-report';

const ORB_REPORTING_CUTOFF_DATE = new Date(2025, 2, 1, 0, 0, 0);

type AdminConsumptionReportProps = {
  tenant: Tenant;
};

// Conditional display of the Consumption Report. There are 2 tables:
// 1. The new "Consumption" component which uses data from Orb
// 2. The "OldUsage" component which is based on the old component and uses the UsageHistory data
// Orb data has a "cutoff" date from which the data is available. In "Consumption" users can see that data
// up to the cutoff date. To see older data they can go to "OldUsage", but only up to the
// cutoff date.
export const AdminConsumptionReport = ({ tenant }: AdminConsumptionReportProps) => {
  const [selectedTable, setSelectedTable] = useState<'new' | 'old'>('new');

  const handleViewOlderData = () => {
    setSelectedTable('old');
  };

  const handleViewNewData = () => {
    setSelectedTable('new');
  };

  return (
    <>
      {selectedTable === 'new' ? (
        <ConsumptionReport tenant={tenant} orb_reporting_cutoff_date={ORB_REPORTING_CUTOFF_DATE} />
      ) : (
        <OldUsageReport tenant={tenant} orb_reporting_cutoff_date={ORB_REPORTING_CUTOFF_DATE} />
      )}
      {selectedTable === 'new' ? (
        <Button className="mt-4" onClick={handleViewOlderData} size="small" fill="outlined">
          View older usage data
        </Button>
      ) : (
        <Button className="mt-4" onClick={handleViewNewData} size="small" fill="outlined">
          Return to new consumption view
        </Button>
      )}
    </>
  );
};
