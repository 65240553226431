import React, { useState } from 'react';
import * as yup from 'yup';
import { Alert, FormSelect, Checkbox } from 'foundation';
import { Database, getDefaultName, Tier } from 'entities/database';
import { tierDisplayName } from 'entities/tenant';
import { validateYup, Validation } from 'utils/validation';
import { CloneToExistingDatabaseFormData, CloneToExistingDatabaseFormOptions } from './form-data';
import { sizeStringToBytesInt } from 'components/utils';
import { DatabaseNameInput } from 'application/create-db/form-essentials';
import { useCmeks } from 'remote/resources/encryption-keys';
import { EncryptionKey, EncryptionKeyStatus } from 'types/encryption-keys';
import { CloneDatabaseChangeCmekWarning } from 'components/application/clone-db/index';

const schema = yup.object({
  name: DatabaseNameInput.yupValidator.label('Name'),
  targetDbid: yup.string().required('You must select a database to overwrite'),
  confirmed: yup
    .bool()
    .required(
      'You must confirm that you understand the consequences of cloning into an existing database'
    )
    .isTrue(
      'You must confirm that you understand the consequences of cloning into an existing database'
    ),
});

export const validate = (
  data: CloneToExistingDatabaseFormData,
  { onlyRequired }: { onlyRequired?: boolean } = {}
) => validateYup(schema, data, onlyRequired || false);

export const defaults = (
  options: CloneToExistingDatabaseFormOptions
): CloneToExistingDatabaseFormData => {
  return {
    name: getDefaultName(options.sourceDatabase.Name, 'Clone'),
    targetDbid: undefined,
    confirmed: undefined,
  };
};

const messageText =
  'Cloning into an existing database will replace all existing data, so please be certain. If you want to keep the current data, please take a snapshot and export it.';

const getTargetDatabaseOptions = (sourceDatabase: Database, targetDatabases: Database[]) => {
  // Consider including applied settings in API response for Free tier DBs
  const sourceStorage =
    sourceDatabase.Tier === Tier.FREE ? '2GB' : sourceDatabase.AppliedSettings.Storage;

  if (!sourceStorage) return [];
  return targetDatabases
    .filter(target => sourceDatabase.CloudProvider === target.CloudProvider)
    .map(targetDatabase => {
      const option = {
        label: targetDatabase.Name,
        value: targetDatabase.DbId,
        key: targetDatabase.DbId,
        isDisabled: false,
        description: `Memory: ${targetDatabase.AppliedSettings.Memory ||
          '-'}; Storage: ${targetDatabase.AppliedSettings.Storage || '-'}; CPU: ${targetDatabase
          .AppliedSettings.Cpu || '-'}`,
      };
      if (
        !targetDatabase.AppliedSettings.Storage ||
        sizeStringToBytesInt(targetDatabase.AppliedSettings.Storage) <
          sizeStringToBytesInt(sourceStorage)
      ) {
        option.label += ' (Instance is not large enough to clone into)';
        option.isDisabled = true;
      }
      return option;
    });
};

const getEntityDisplayName = (tier: Tier) => {
  switch (tier) {
    case Tier.AURA_DSE:
    case Tier.GDS:
      return 'instance';
    default:
      return 'database';
  }
};

interface Props {
  data: CloneToExistingDatabaseFormData;
  options: CloneToExistingDatabaseFormOptions;
  onChange: (data: CloneToExistingDatabaseFormData) => any;
  validation: Validation<CloneToExistingDatabaseFormData>;
  disabled?: boolean;
}

const CloneToExistingDatabaseFormFields = ({
  data,
  options,
  onChange,
  validation,
  disabled,
}: Props) => {
  const targetTierDisplayName = tierDisplayName(options.tenant, options.targetTier);
  const entityDisplayName = getEntityDisplayName(options.targetTier);
  const handleDatabaseNameChange = name => onChange({ ...data, name });
  const handleConfirmedChange = ({ target: { checked } }) => {
    onChange({
      ...data,
      confirmed: hasDifferentEncryption ? checked && differentEncryptionConfirmed : checked,
    });
    setDefaultConfirmed(checked);
  };
  const handleConfirmedEncyptionKeyChange = ({ target: { checked } }) => {
    onChange({ ...data, confirmed: defaultConfirmed && checked });
    setDifferentEncryptionConfirmed(checked);
  };

  const handleTargetDbidChange = ({ value }) => {
    onChange({ ...data, targetDbid: value, confirmed: false });
    if (options.tenant.capabilities.cmek) {
      setDifferentEncryptionConfirmed(false);
      setDefaultConfirmed(false);
    }
  };
  const [defaultConfirmed, setDefaultConfirmed] = useState(false);
  const [differentEncryptionConfirmed, setDifferentEncryptionConfirmed] = useState(false);

  const targetDatabase = options?.targetDatabases.find(db => db.DbId === data.targetDbid);
  const sourceDatabase = options.sourceDatabase;
  const cmeksQuery = useCmeks(options.tenant);
  const customKeys: EncryptionKey[] = cmeksQuery.isSuccess ? cmeksQuery.data : [];
  const readyKeys = customKeys.filter(k => k.status === EncryptionKeyStatus.READY);
  const applicableKeys = readyKeys.filter(k => k.tier === sourceDatabase.Tier);

  const targetDatabaseOptions = getTargetDatabaseOptions(
    options.sourceDatabase,
    options.targetDatabases
  );

  const sourceEncryption = sourceDatabase?.EncryptionKey?.encryptionKeyRef;
  const targetEncryption = targetDatabase?.EncryptionKey?.encryptionKeyRef;

  const hasDifferentEncryption =
    options.tenant.capabilities.cmek && data.targetDbid && sourceEncryption !== targetEncryption;

  const formatOptionLabel = ({ label, description }, { context }) => {
    if (context === 'value') {
      return (
        <div style={{ whiteSpace: 'break-spaces', display: 'flex', flexDirection: 'row' }}>
          {label}
        </div>
      );
    } else if (context === 'menu') {
      return (
        <div>
          <span
            className="tw-break-words"
            style={{ float: 'right', margin: '0em 0em 0em 1em', color: '#bcc0c9' }}
          >
            {description ? description : ''}
          </span>
          <span className="tw-break-words">{label}</span>
        </div>
      );
    }
  };

  return (
    <>
      <div className="tw-mb-6" style={{ display: 'grid', gap: '16px' }}>
        <DatabaseNameInput
          value={data.name}
          onChange={handleDatabaseNameChange}
          validationError={validation?.name?.message}
          disabled={disabled}
          label="Name"
        />
        <FormSelect
          value={data.targetDbid}
          onChange={handleTargetDbidChange}
          errorText={validation?.targetDbid?.message}
          label={`${targetTierDisplayName} ${entityDisplayName}`}
          placeholder={
            targetDatabaseOptions.length
              ? 'Select'
              : `You have no ${targetTierDisplayName} ${entityDisplayName}s available that match the clone criteria`
          }
          disabled={disabled}
          options={targetDatabaseOptions}
          data-testid="clone-database-existing-target-select"
          formatOptionLabel={formatOptionLabel}
          closeMenuOnSelect={true}
        />
      </div>
      {hasDifferentEncryption && (
        <Alert
          type="warning"
          icon={true}
          description={
            <div className="tw-pb-1">
              <CloneDatabaseChangeCmekWarning
                keys={applicableKeys}
                encryptionKey={targetEncryption}
              />
              <div className="tw-my-2 tw-ml-3">
                <Checkbox
                  label="I understand"
                  checked={differentEncryptionConfirmed}
                  onChange={handleConfirmedEncyptionKeyChange}
                  disabled={disabled}
                  data-testid="other-encryption-confirmation-check"
                />
              </div>
            </div>
          }
          className="tw-mb-6"
        />
      )}
      <div>
        <Alert className="tw-my-4" description={messageText} />
        {sourceDatabase.VectorOptimized && (
          <Alert
            className="tw-my-4"
            description="Configurations such as Vector Optimisation or Graph Analytics are not applied to existing instances."
          />
        )}
        <div className="tw-mt-4">
          <Checkbox
            label="I understand"
            checked={defaultConfirmed}
            onChange={handleConfirmedChange}
            disabled={disabled}
            data-testid="pricing-confirmation-check"
            className="clone-existing-confirm-checkbox"
          />
        </div>
        {validation?.confirmed?.message && (
          <Alert type="danger" className="tw-mt-2">
            {validation?.confirmed?.message}
          </Alert>
        )}
      </div>
    </>
  );
};

export default CloneToExistingDatabaseFormFields;
