import { Tier } from 'entities/database';

export interface Usage {
  total_cost: string;
  total_cost_type: string;
  total_breakdown_count?: number;
  discount?: Discount;
  breakdown?: UsageBreakdown;
  has_previous_pricing_plan?: boolean;
  usage_period_end?: string;
  usage_period_start?: string;
}

export interface Discount {
  promotion_name: string;
  voucher_code?: string;
  dollar_amount_off: string;
}

export interface UsageBreakdownItem {
  dbid: string;
  name: string;
  consumption_units: number;
  unit_type: string;
  unit_cost_in_dollars: string;
  cost: string;
  cost_type: string;
  billing_category: string;
  end_time: string;
  tier: Tier;
  pricing_plan: string;
  product_name: string;
}

export interface UsageBreakdown extends Array<UsageBreakdownItem> {}

export interface TransformedDiscount {
  promotionName: string;
  voucherCode?: string;
  dollarAmountOff: string;
}

export interface TransformedBreakdownItem {
  dbid: string;
  name: string;
  consumptionUnits: number;
  unitType: string;
  unitCostInDollars: string;
  cost: string;
  costType: string;
  billingCategory: string;
  endTime: string;
  tier: Tier;
  pricingPlan: string;
  productName: string;
}

export interface TransformedBreakdown extends Array<TransformedBreakdownItem> {}

export interface TransformedUsage {
  totalCost: string;
  totalCostType?: string;
  totalBreakdownCount?: number;
  discount?: TransformedDiscount;
  breakdown?: TransformedBreakdown;
  hasPreviousPricingPlan?: boolean;
  usagePeriodEnd?: string;
  usagePeriodStart?: string;
}

export enum FilterType {
  DATE = 'date',
  INSTANCE = 'instance',
}

export interface Filter {
  type: FilterType;
  label: string;
  value: string;
}

export interface DateFilter extends Filter {
  start: Date;
  end: Date;
}

export type DateRange = [Date | null, Date | null];

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

interface ColumnSort {
  id: string;
  desc: boolean;
}

export type Sorting = ColumnSort[];
