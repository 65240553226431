import React, { useEffect, useMemo, useState } from 'react';
import { Alert, IconButton, LoadingSpinner, Page, Typography } from 'components/foundation';
import Actions from 'actions';
import { User, Action } from 'types/user';
import UsersResource from 'remote/resources/users';
import { usePermissions } from 'store';
import { planTypeDisplayName } from 'entities/tenant';
import { UsersTable } from './users-table';
import globals from 'browser/globals';
import { NotificationLevel } from 'state/notifications';
import { KeyPairItem, KeyPairSection } from 'components/application/key-pair';

export const ViewUserDetailsPage = ({ userId }: { userId: string }) => {
  const permission = usePermissions();
  const canManageUsers = permission.allow(Action.READ, 'users');
  const [userDetails, setUserDetails] = useState<User>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>(null);

  useEffect(() => {
    if (!canManageUsers) return;

    const fetchUserDetails = async () => {
      try {
        setLoading(true);
        setError(null);
        setUserDetails(null);

        const result = await UsersResource.search(userId);

        if (result.length === 1) {
          setUserDetails(result[0]);
        } else {
          setError(`User ${userId} not found`);
        }
      } catch (e) {
        setError(`Failed to load user details: ${e?.message ?? e}`);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, [canManageUsers, userId]);

  const userDetailsTable = useMemo(
    () =>
      userDetails && (
        <UsersTable
          // Pretend not terminated so it doesn't show up as red
          // as we already show the warning above
          users={[{ ...userDetails, Terminated: false }]}
          displaySearch={false}
          projectDetailsOnly={true}
        />
      ),
    [userDetails]
  );

  return (
    <Page fullWidth>
      {canManageUsers ? (
        <>
          <div className="tw-flex tw-w-max">
            <h5 className="tw-ml-6 tw-mt-1">User details</h5>
            <IconButton
              title="Copy shareable URL for this page"
              iconName="ShareIconOutline"
              size="medium"
              clean={true}
              className="tw-ml-2 tw-h-[28px] tw-w-[28px]"
              onClick={() => {
                globals.window.navigator.clipboard.writeText(
                  `${globals.window.location.origin}/${globals.window.location.hash}`
                );
                Actions.notifications.show({
                  level: NotificationLevel.INFO,
                  title: 'Shareable URL copied',
                  message:
                    'You can share this URL with colleagues with the customer-support-viewer role.',
                });
              }}
            />
          </div>

          <div>
            {loading && <LoadingSpinner size="large" className="tw-my-4 tw-mx-6" />}
            {error && <Alert type="danger">{error}</Alert>}
            {userDetails && (
              <>
                <div className="tw-w-max tw-mb-8">
                  {userDetails.Terminated && (
                    <Alert type="warning" icon={true} className="tw-w-max tw-mx-6 tw-my-2 tw-pr-6">
                      User Terminated
                    </Alert>
                  )}
                  <div className="tw-my-6 tw-mx-6">
                    <KeyPairSection>
                      <KeyPairItem label="Email" isCopyable={true} copyText={userDetails.Email}>
                        <code>{userDetails.Email}</code>
                      </KeyPairItem>
                      <KeyPairItem label="User ID" isCopyable={true} copyText={userDetails.UserId}>
                        <code>{userDetails.UserId}</code>
                      </KeyPairItem>
                      <KeyPairItem label="Plan Type">
                        <code className="tw-pb-32">
                          {planTypeDisplayName((userDetails as any).PlanType)}
                        </code>
                      </KeyPairItem>
                      <KeyPairItem label="Created At">
                        <span className="tw-mr-3">
                          {new Date(userDetails.CreatedAt).toLocaleString()}
                        </span>
                        <Typography variant="body-small" as="i">
                          (may be estimated in older data)
                        </Typography>
                      </KeyPairItem>
                    </KeyPairSection>
                  </div>
                </div>
                <div className="tw-my-8">{userDetailsTable}</div>
              </>
            )}
          </div>
        </>
      ) : (
        <Alert type="danger">Access Denied</Alert>
      )}
    </Page>
  );
};
