import React, { useMemo, useState, useEffect, useCallback } from 'react';
import Button from 'foundation/button';

import { useSession } from 'store';
import {
  Database,
  findDatabaseSize,
  gibibytesStringToInt,
  isSizeAvailableForVersion,
  isSizeAvailableInRegion,
  Tier,
} from 'entities/database';
import DatabasesResource from 'remote/resources/databases';
import { ConfigureDatabaseFormData } from 'application/configure-db/form-data';
import { ResizeDatabaseForm, PriceDescription, validate } from './form-resize-database';
import Actions from 'actions';
import { Dialog } from 'foundation/dialog';
import { BillingMethod, getCloudProvider } from 'entities/tenant';
import { Form } from 'foundation/form-element';
import logger from 'logger';
import { useNotify } from 'state/notifications';
import { Checkbox, Alert } from 'foundation';
import { GdsPluginForm, VectorOptimizedForm } from './form-additional-settings';

type Props = {
  open: boolean;
  onClose: () => void;
  database: Database;
};

const ConfigureDatabaseModal = ({ open, onClose, database }: Props) => {
  const session = useSession();
  const notify = useNotify();
  const { tenant } = useSession();
  const availableByTierSizes = session.databaseSizes[database.Tier].filter(
    size =>
      !size.deprecated &&
      size.cloud_provider === database.CloudProvider &&
      isSizeAvailableInRegion(size, database.Region) &&
      isSizeAvailableForVersion(size, database.DesiredSettings.Version, tenant) &&
      !size.is_trial
  );

  const initialSize = findDatabaseSize(
    availableByTierSizes,
    database.DesiredSettings.Memory,
    database.DesiredSettings.Storage,
    database.CloudProvider
  );

  const [data, setData] = useState<ConfigureDatabaseFormData>(() => {
    return {
      confirmed: false,
      size: initialSize,
      vectorOptimized: database.VectorOptimized,
      gdsPlugin: database.GDSPlugin || false,
    };
  });
  const [databaseDetail, setDatabaseDetail] = useState<Database | null>(null);

  const fetchDatabase = useCallback(async () => {
    try {
      const dbDetail = await DatabasesResource.get(database.DbId);
      setDatabaseDetail({
        ...dbDetail,
        ResizeThresholdGibibytes: dbDetail.ResizeThresholdGibibytes,
      });
    } catch (err) {
      logger.error(err);
    }
  }, [database]);

  useEffect(() => {
    fetchDatabase();
  }, [database]);
  const [updateRequestInProgress, setUpdateRequestInProgress] = useState(false);

  const handleSizeChange = (newData: ConfigureDatabaseFormData) => {
    const memoryInt = gibibytesStringToInt(newData.size.memory);
    let { vectorOptimized, gdsPlugin } = data;
    if (memoryInt < 4) {
      vectorOptimized = false;
      gdsPlugin = false;
    }
    setData({
      ...data,
      size: newData.size,
      vectorOptimized,
      gdsPlugin,
      confirmed: newData.confirmed,
    });
  };

  const handleConfirmChange = (checked: boolean) => setData({ ...data, confirmed: checked });

  const updateDatabase = async () => {
    setUpdateRequestInProgress(true);

    try {
      await Actions.databases.updateDatabase(database.DbId, {
        Memory: data.size.memory,
        Storage: data.size.storage,
        Cpu: data.size.cpu,
        VectorOptimized: data.vectorOptimized,
        GDSPlugin: data.gdsPlugin,
      });
      setUpdateRequestInProgress(false);
      onClose();
    } catch (e) {
      setUpdateRequestInProgress(false);
      logger.error(e);
      notify.error('Failed to resize instance');
    }
  };

  const validationOk = useMemo(() => validate(data, initialSize), [data, initialSize]);
  const isPrepaidTenant = session.tenant.billingMethod === BillingMethod.PREPAID;
  const isPrepaidOnlyOptionSelected = useMemo(() => !!data?.size?.prepaid_only, [data]);
  const dataHasChanged =
    data.size !== initialSize ||
    data.vectorOptimized !== database.VectorOptimized ||
    data.gdsPlugin !== database.GDSPlugin;

  const disableUpdateDatabase = useMemo(() => {
    return !dataHasChanged || !validationOk || (isPrepaidOnlyOptionSelected && !isPrepaidTenant);
  }, [validationOk, isPrepaidOnlyOptionSelected, isPrepaidTenant, dataHasChanged]);

  const configurationMessage = [Tier.GDS, Tier.AURA_DSE].includes(database.Tier) ? (
    <p>Re-configuring your instance will schedule a small amount of unavailability.</p>
  ) : (
    'Read / write availability is maintained during a configuration change.'
  );
  return (
    <Dialog
      open={open}
      onClose={onClose}
      size="unset"
      modalProps={{
        'data-testid': 'configure-database-modal',
        className: 'tw-max-w-[850px] !tw-overflow-y-auto',
      }}
    >
      <Dialog.Header>Configure instance</Dialog.Header>
      <Form onSubmit={updateDatabase} data-testid="configure-db-form">
        <Dialog.Content className="tw-flex tw-flex-col tw-gap-6 tw-mb-6">
          {/* RESIZE MENU */}
          <div>
            <Dialog.Subtitle>Resize</Dialog.Subtitle>
            <ResizeDatabaseForm
              data={data}
              onChange={handleSizeChange}
              database={database}
              cloudProvider={getCloudProvider(session.providerConfigs)}
              resizeThreshold={databaseDetail?.ResizeThresholdGibibytes}
              initialSize={initialSize}
              sizes={availableByTierSizes}
            />
          </div>

          {/* ADDITIONAL SETTINGS */}
          <Dialog.Subtitle>Additional Settings</Dialog.Subtitle>
          <div>
            {/* Check if GDS Plugin is allowed on namespace */}
            {database.Tier === Tier.PROFESSIONAL && (
              <GdsPluginForm data={data} onChange={setData} database={database} />
            )}
            <VectorOptimizedForm data={data} onChange={setData} database={database} />
          </div>

          {/* ALERTS & FINAL CONFIRMATION */}
          <Alert description={configurationMessage} />
          {dataHasChanged && (
            <>
              {data.gdsPlugin !== database.GDSPlugin && !data.gdsPlugin && (
                <Alert description="GDS Plugin has changed." type="info" className="my-4">
                  {gibibytesStringToInt(data.size!.memory) < 4
                    ? `Resizing to a ${gibibytesStringToInt(
                        data.size!.memory
                      )}GB instance will remove `
                    : 'You are removing '}
                  the GDS plugin from this instance.
                </Alert>
              )}

              {data.vectorOptimized !== database.VectorOptimized && !data.vectorOptimized && (
                <Alert description="Vector Optimized has changed." type="info" className="my-4">
                  {gibibytesStringToInt(data.size!.memory) < 4
                    ? `Resizing to a ${gibibytesStringToInt(
                        data.size!.memory
                      )}GB instance will remove `
                    : 'You are removing '}
                  vector-optimized configuration from this instance. Vector Search performance might
                  be affected.
                </Alert>
              )}

              {data.size && data.size !== initialSize && (
                <>
                  <PriceDescription
                    planType={session.planType}
                    tier={database.Tier}
                    size={data.size}
                  />

                  <Checkbox
                    label="I accept"
                    checked={data.confirmed}
                    onChange={({ target }) => handleConfirmChange(target.checked)}
                    data-testid="pricing-confirmation-check"
                  />
                </>
              )}
            </>
          )}
        </Dialog.Content>

        {/* FORM SUBMISSION */}
        <Dialog.Actions>
          <Button
            onClick={onClose}
            data-testid="clone-cancel-button"
            fill="outlined"
            color="neutral"
            disabled={updateRequestInProgress}
          >
            Cancel
          </Button>
          <Button
            disabled={disableUpdateDatabase}
            onClick={updateDatabase}
            loading={updateRequestInProgress}
            data-testid="update-database"
          >
            Configure
          </Button>
        </Dialog.Actions>
      </Form>
    </Dialog>
  );
};

export default ConfigureDatabaseModal;
