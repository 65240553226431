import auth from './login-actions';
import createDatabase from './create-database-action';
import uploadDatabaseDump from './upload-database-dump-action';
import customerAction from './customer-actions';
import destroyDatabase from './destroy-database-action';
import errorActions from './error-actions';
import estimations from './estimate-database-action';
import namespaces from './namespace-actions';
import notifications from './notification-actions';
import takeSnapshot from './take-snapshot-action';
import restoreSnapshot from './restore-snapshot-action';
import downloadSnapshot from './download-snapshot-action';
import logsActions from './database-logs-actions';
import updateDatabase from './update-database-action';
import updateDatabaseName from './update-database-name-action';
import usageChanges from './fetch-usage-action';
import userDetailsActions from './user-details-actions';
import consumption from './fetch-consumption-action';

import userTrackingActions from './user-tracking-actions';
import loadAnalytics from './load-segment-action';
import productActions from './product-actions';
import setMFA from './set-mfa-action';
import getAvailableSizes from './database-sizes-actions';
import cloneToExistingDatabase from './clone-to-existing-database-action';
import clearDatabase from './clear-database-action';
import transferDatabase from './transfer-database-action';
import * as oauthActions from './oauth-actions';
import * as navigateActions from './navigation-actions';
import roleActions from './role-actions';
import inviteActions from './invite-actions';
import tenantActions from './tenant-actions';
import marketplaceActions from './marketplace-actions';

export default {
  auth,
  notifications,
  userDetails: userDetailsActions,
  customer: customerAction,
  consumption: consumption,
  billingUsage: usageChanges,
  downloadSnapshot,
  estimations,
  namespaces,
  databases: {
    createDatabase,
    uploadDatabaseDump,
    destroyDatabase,
    takeSnapshot,
    restoreSnapshot,
    updateDatabase,
    updateDatabaseName,
    getAvailableSizes,
    cloneToExistingDatabase,
    clearDatabase,
    transferDatabase,
  },
  errors: {
    report: errorActions.report,
  },
  invites: inviteActions,
  oauth: oauthActions,
  logs: logsActions,
  userTracking: userTrackingActions,
  analytics: loadAnalytics,
  products: productActions,
  marketplace: marketplaceActions,
  projects: {
    ...inviteActions,
    setMFA,
  },
  navigate: navigateActions,
  roles: roleActions,
  tenants: tenantActions,
};
