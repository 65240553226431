import ApiClient from 'remote/api-client';
import {
  Consumption,
  DateFilter,
  Filter,
  FilterType,
  Pagination,
  Sorting,
} from 'types/consumption';
import { format, startOfDay, endOfDay, isToday, subDays } from 'date-fns';
import { MAXIMUM_DAYS } from 'components/application/consumption/const';

const DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss'Z'";

const getFormattedDateRange = (filters: Filter[] | DateFilter[]) => {
  const now = new Date();
  const dateFilter = filters.find(filter => filter.type === FilterType.DATE) as DateFilter;
  let start = dateFilter ? dateFilter.start : subDays(now, MAXIMUM_DAYS - 1);
  start = dateFilter.value === 'Last 24 hours' ? start : startOfDay(start);
  const end =
    dateFilter && dateFilter.end && !isToday(dateFilter.end) ? endOfDay(dateFilter.end) : null;
  return {
    start: encodeURI(format(start, DATE_FORMAT)),
    end: end ? encodeURI(format(end, DATE_FORMAT)) : null,
  };
};

const getSortingKey = (id: string) => {
  switch (id) {
    case 'unitCostInDollars':
      return 'unit_cost_in_dollars';
    case 'self-serve-cost':
    case 'enterprise-consumption':
      return 'cost';
    case 'productName':
      return 'product_name';
    case 'pricingPlan':
      return 'pricing_plan';
    case 'unitOfMeasure':
      return 'unit_of_measure';
    case 'billingStatus':
      return 'billing_status';
    default:
      return id;
  }
};

const get = (
  tenantId: string,
  filters?: Filter[] | DateFilter[],
  pagination?: Pagination,
  sorting?: Sorting,
  search?: string
): Promise<Consumption> => {
  let dateFilter = '';
  let paginationParameter = '';
  let searchFilter = '';
  let sortParameter = '';
  let hasPreviousParameter = false;

  if (filters && filters.length > 0) {
    const { start, end } = getFormattedDateRange(filters);
    dateFilter = `?StartDate=${start}${end ? '&EndDate=' + end : ''}`; // in case of custom range end date is not null
    hasPreviousParameter = true;
  }

  if (pagination) {
    paginationParameter = `${hasPreviousParameter ? '&' : '?'}Page=${pagination.pageIndex +
      1}&PageSize=${pagination.pageSize}`;
    hasPreviousParameter = true;
  }

  if (sorting && sorting.length > 0) {
    const key = encodeURI(getSortingKey(sorting[0].id));
    sortParameter += `${hasPreviousParameter ? '&' : '?'}SortBy=${key}${
      sorting[0].desc ? '&SortOrder=desc' : ''
    }`;
    hasPreviousParameter = true;
  }

  if (search && search.length > 0) {
    searchFilter += `${hasPreviousParameter ? '&' : '?'}Search=${encodeURI(search)}`;
    hasPreviousParameter = true;
  }

  return ApiClient.get(
    `/tenants/${tenantId}/consumption${dateFilter}${paginationParameter}${sortParameter}${searchFilter}`
  ).promise();
};

export default { get };
