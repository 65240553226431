import React, { ChangeEvent } from 'react';
import { Validation } from 'utils/validation';
import { FormInput, FormSelect } from 'foundation';
import {
  BillingAccount,
  BillingModel,
  BillingProcessor,
  PaymentMethod,
} from 'types/billing-account';
import { PricingPlanName } from 'entities/tenant';

export interface EditBillingAccountFormFieldsProps {
  billingAccount: BillingAccount;
  onChange(data: BillingAccount): void;
  validation: Validation<BillingAccount>;
}

const BillingAccountEditFormFields = ({
  billingAccount,
  onChange,
  validation,
}: EditBillingAccountFormFieldsProps) => {
  if (!billingAccount) {
    return null;
  }
  const updateData = (param: string, value: any) => onChange({ ...billingAccount, [param]: value });
  const handleBillingModelChange = ({ value }) => {
    updateData('billingModel', value);
  };
  const handleBillingProcessorChange = ({ value }) => {
    updateData('billingProcessor', value);
  };
  const handlePaymentMethodChange = ({ value }) => {
    updateData('paymentMethod', value);
  };
  const handlePricingPlanChange = ({ value }) => {
    updateData('pricingPlan', value);
  };
  const handleReasonChange = ({ target: { value: reason } }: ChangeEvent<HTMLInputElement>) => {
    updateData('reason', reason);
  };
  return (
    <>
      <div className="tw-mt-4">
        <FormSelect
          label="Billing Model"
          fluid
          data-testid="billing-account-edit-billing-model-input"
          options={Object.values(BillingModel).map(v => ({ label: v, value: v, key: v }))}
          value={billingAccount.billingModel}
          onChange={handleBillingModelChange}
          errorText={validation?.billingModel?.message}
        />
      </div>
      <div className="tw-mt-4">
        <FormSelect
          label="Billing Processor"
          fluid
          data-testid="billing-account-edit-billing-processor-input"
          options={Object.values(BillingProcessor).map(v => ({ label: v, value: v, key: v }))}
          value={billingAccount.billingProcessor}
          onChange={handleBillingProcessorChange}
          errorText={validation?.billingProcessor?.message}
        />
      </div>
      <div className="tw-mt-4">
        <FormSelect
          label="Payment Method"
          fluid
          data-testid="billing-account-edit-payment-method-input"
          options={Object.values(PaymentMethod).map(v => ({ label: v, value: v, key: v }))}
          value={billingAccount.paymentMethod}
          onChange={handlePaymentMethodChange}
          errorText={validation?.paymentMethod?.message}
        />
      </div>
      <div className="tw-mt-4">
        <FormSelect
          label="Pricing Plan"
          fluid
          data-testid="billing-account-edit-pricing-plan-input"
          options={Object.values(PricingPlanName).map(v => ({ label: v, value: v, key: v }))}
          value={billingAccount.pricingPlan}
          onChange={handlePricingPlanChange}
          errorText={validation?.pricingPlan?.message}
        />
      </div>
      <div className="tw-mt-4">
        <FormInput
          fluid
          label="Reason"
          aria-label="Reason"
          data-testid="billing-account-edit-reason-input"
          value={billingAccount.reason}
          onChange={handleReasonChange}
          errorText={validation?.reason?.message}
        />
      </div>
    </>
  );
};

export default BillingAccountEditFormFields;
