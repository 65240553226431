import React, { useState } from 'react';
import { FormInput, Alert, Button, Form, Checkbox } from 'foundation';
import customEndpointResources from 'remote/resources/custom-endpoints';
import { InternalCustomEndpointDetails } from 'types/custom-endpoints';
import { CustomEndpointsTable } from './custom-endpoints-table';

const SearchByDbForm = ({ ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [customEndpoints, setCustomEndpoints] = useState<InternalCustomEndpointDetails[]>([]);

  const handleQueryChange = ({ target: { value } }) => setQuery(value);

  const handleIncludeDeletedChange = ({ target: { checked } }) => setIncludeDeleted(checked);

  const handleSearchSubmit = async () => {
    if (!query) return;
    setLoading(true);
    setCustomEndpoints([]);
    setError(null);

    try {
      const result = await customEndpointResources.internalListByQuery({
        dbId: query.trim(),
        includeDeleted: includeDeleted,
      });
      setCustomEndpoints(result);
    } catch (err) {
      setError(String(err));
    }
    setLoading(false);
  };

  return (
    <div {...rest}>
      <p className="tw-mb-2">This form enables you to search for a custom endpoint by instance.</p>
      <p className="tw-mb-2">Only exact ID matches can be found.</p>

      <Form onSubmit={handleSearchSubmit}>
        <FormInput
          fluid
          value={query}
          onChange={handleQueryChange}
          label="Instance ID"
          data-testid="lookup-custom-endpoints-by-query-input"
          name="dbid"
          autoComplete="dbid"
          helpText="The exact ID of the instance"
        />
        <div className="tw-flex tw-flex-row tw-gap-5 tw-mt-8 tw-items-center">
          <Checkbox
            label={'Include deleted entities'}
            checked={includeDeleted}
            onChange={handleIncludeDeletedChange}
          />
          <Button
            type="submit"
            color="primary"
            loading={loading}
            disabled={!query}
            data-testid="lookup-custom-endpoints-by-query-button"
          >
            Search
          </Button>
        </div>

        {error && (
          <Alert
            type="danger"
            data-testid="lookup-custom-endpoints-error-message"
            className="tw-mt-8"
          >
            {error}
          </Alert>
        )}
      </Form>

      {customEndpoints.length > 0 && (
        <CustomEndpointsTable
          customEndpoints={customEndpoints}
          data-testid="search-custom-endpoints-result-table"
        />
      )}
    </div>
  );
};

export default SearchByDbForm;
