import React, { useMemo, useState } from 'react';

import DatabaseName from 'application/db-name';
import { formatSizeString } from 'components/utils';
import formatDollars from 'utils/format-dollars';
import { Database, findDatabaseSize, Tier } from 'entities/database';
import { useSession } from 'store';
import { Button, Alert, Dialog, List, Checkbox } from 'foundation';
import { useTerminology } from 'utils/terminology';
import { getHasCDCEnrichmentMode } from '../edit-cdc-enrichment-mode/helpers';
import { CDCPauseDBWarning } from 'components/application/edit-cdc-enrichment-mode/cdc-warnings';

const PAUSE_COST_FACTOR = 0.2;

const getPauseCostString = (costPerHour: string): string => {
  const pauseCost = parseFloat(costPerHour) * PAUSE_COST_FACTOR;
  return formatDollars(pauseCost);
};

const getPauseCostMonthString = (costPerHour: string): string => {
  const pauseCost = parseFloat(costPerHour) * PAUSE_COST_FACTOR * 24 * 30; // 24 hours * 30 days
  return formatDollars(pauseCost);
};

interface ContentProps {
  database: Database;
}

const ModalTextContent = ({ database }: ContentProps) => {
  const session = useSession();
  const t = useTerminology();

  const availableSizes = session.databaseSizes[database.Tier];
  const size = useMemo(
    () =>
      findDatabaseSize(
        availableSizes,
        database.DesiredSettings.Memory,
        database.DesiredSettings.Storage,
        database.CloudProvider
      ),
    [database]
  );
  const memory = formatSizeString(database.DesiredSettings.Memory);
  // If a matching size cannot be found, we should not crash the application.
  // However we do not know what values to render, so the experience will be suboptimal.
  const pauseCostString = getPauseCostString(size?.cost_per_hour);
  const pauseCostMonthString = getPauseCostMonthString(size?.cost_per_hour);
  const gdsAvailable = session.tenant.capabilities.gds_sessions;

  if (database.ProTrialEndTime) {
    return <div>Note: Pausing the instance does not pause your trial.</div>;
  }

  if ([Tier.PROFESSIONAL, Tier.MTE].includes(database.Tier)) {
    return (
      <>
        <div>
          You will be charged {pauseCostString} per hour while an instance of this size is paused,
          and leaving this database paused for 30 days will cost you {pauseCostMonthString}.
        </div>
        <div className="tw-mt-3">All charges are rounded to the nearest cent.</div>
        <div className="tw-mt-3">
          Your instance will be automatically resumed in 30 days, at which point you will be charged
          the full running cost.
        </div>
      </>
    );
  }

  if (database.Tier === Tier.GDS) {
    return (
      <>
        <div>
          You will be charged {pauseCostString} per hour while your {memory} instance is paused.
        </div>
        <div className="tw-mt-3">
          Your instance will be automatically resumed in 30 days, at which point you will be charged
          the full running cost.
        </div>
        {gdsAvailable === true && (
          <div className="tw-mt-3">
            Data Science sessions created from this instance will not be able to write back results
            while the database is paused. We recommend that you terminate all connected Data Science
            sessions before pausing the instance.
          </div>
        )}
      </>
    );
  }

  if ([Tier.ENTERPRISE, Tier.AURA_DSE].includes(database.Tier)) {
    return (
      <>
        <List bulleted>
          <List.Item>
            Paused {t('databases')} will consume credits at 20% of the normal running rate.
          </List.Item>
          <List.Item>Backups will be retained in line with usual retention periods.</List.Item>
          <List.Item>
            Unless you resume or destroy your {t('database')}, it will remain paused for the next 30
            days, at which point {t('AuraDB')} will automatically resume your {t('database')} for
            you.
          </List.Item>
        </List>
        <p>Please refer to your contract for more information.</p>
      </>
    );
  }

  return null;
};

interface Props {
  open: boolean;
  onCancelClick?: () => void;
  onConfirmClick: () => void;
  loading: boolean;
  error?: any;
  errorHeader?: any;
  database: Database;
}

const PauseDatabaseModal = ({
  open,
  onCancelClick,
  onConfirmClick,
  loading,
  error,
  errorHeader,
  database,
}: Props) => {
  const [userConfirmed, setUserConfirmed] = useState(false);

  const handlePauseClick = () => {
    onConfirmClick();
  };

  const handleUserConfirm = () => {
    setUserConfirmed(!userConfirmed);
  };

  const hasCDCEnrichmentMode = getHasCDCEnrichmentMode(database);
  return (
    <Dialog
      open={open}
      modalProps={{ 'data-testid': 'pause-db-modal' }}
      size="medium"
      onClose={onCancelClick}
    >
      <Dialog.Header data-testid="pause-db-modal-header" className="tw-truncate">
        Are you sure you want to pause <DatabaseName Name={database.Name} DbId={database.DbId} />?
      </Dialog.Header>
      <Dialog.Content data-testid="pause-db-modal-content">
        <div className="tw-flex tw-flex-col">
          <div>{hasCDCEnrichmentMode && <CDCPauseDBWarning />}</div>
          <div className="tw-pb-4">
            <Alert
              description="You can pause your database during periods where you don’t need it, and resume
                        at anytime."
            />
          </div>
        </div>
        {database.Tier !== Tier.FREE && <ModalTextContent database={database} />}
      </Dialog.Content>
      {error && (
        <Dialog.Content>
          <Alert
            data-testid="modal-error-message"
            type="danger"
            title={errorHeader}
            description={error}
            className="tw-mt-2"
          />
        </Dialog.Content>
      )}
      <Dialog.Actions className="tw-flex tw-justify-between tw-flex-nowrap tw-items-center">
        <Checkbox
          data-testid="pause-confirm-checkbox"
          label="I understand"
          onChange={handleUserConfirm}
          checked={userConfirmed}
        />
        <div className="tw-space-x-2">
          <Button
            data-testid="pause-cancel-button"
            onClick={onCancelClick}
            disabled={loading}
            color="neutral"
            fill="outlined"
          >
            Cancel
          </Button>
          <Button
            disabled={!userConfirmed}
            onClick={handlePauseClick}
            loading={loading}
            data-testid="pause-confirm-button"
          >
            {loading ? 'Loading' : 'Pause'}
          </Button>
        </div>
      </Dialog.Actions>
    </Dialog>
  );
};

export default PauseDatabaseModal;
