import { Tier } from 'entities/database';

export interface Consumption {
  total_cost: string;
  currency: string;
  total_breakdown_count?: number;
  breakdown?: ConsumptionBreakdown;
  usage_period_end?: string;
  usage_period_start?: string;
  discount?: Discount;
}

export interface Discount {
  promotion_name: string;
  voucher_code?: string;
  dollar_amount_off: string;
}

export interface ConsumptionBreakdownItem {
  dbid: string;
  name: string;
  tier: Tier;
  product_name: string;
  pricing_plan: string;
  usage: string;
  cost: string;
  currency: string;
  unit_of_measure: string;
  unit_cost_in_dollars: string;
  billing_status: string;
}

export interface ConsumptionBreakdown extends Array<ConsumptionBreakdownItem> {}

export interface TransformedDiscount {
  promotionName: string;
  voucherCode?: string;
  dollarAmountOff: string;
}

export interface TransformedConsumptionBreakdownItem {
  dbid: string;
  name: string;
  tier: Tier;
  productName: string;
  pricingPlan: string;
  usage: string;
  cost: string;
  currency: string;
  unitOfMeasure: string;
  unitCostInDollars: string;
  billingStatus: string;
}

export interface TransformedConsumptionBreakdown
  extends Array<TransformedConsumptionBreakdownItem> {}

export interface TransformedConsumption {
  totalCost: string;
  currency: string;
  totalBreakdownCount?: number;
  breakdown?: TransformedConsumptionBreakdown;
  usagePeriodEnd?: string;
  usagePeriodStart?: string;
  discount?: TransformedDiscount;
}

export enum FilterType {
  DATE = 'date',
  INSTANCE = 'instance',
}

export interface Filter {
  type: FilterType;
  label: string;
  value: string;
}

export interface DateFilter extends Filter {
  start: Date;
  end: Date;
}

export type DateRange = [Date | null, Date | null];

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

interface ColumnSort {
  id: string;
  desc: boolean;
}

export type Sorting = ColumnSort[];
