import React, { useEffect, useState } from 'react';
import { Dialog, Button, Alert, Form } from 'foundation';
import { validateYup } from 'utils/validation';
import * as yup from 'yup';
import BillingAccountsResource from 'remote/resources/billing-accounts';
import {
  BillingAccount,
  BillingModel,
  BillingProcessor,
  PaymentMethod,
} from 'types/billing-account';
import BillingAccountEditFormFields from './billing-account-edit-form-fields';
import { PricingPlanName } from 'entities/tenant';

export interface EditBillingAccountModalProps {
  open: boolean;
  closeModal(): void;
  saveAndClose(ba: BillingAccount): void;
  billingAccount: BillingAccount;
}

export const EditBillingAccountModal = ({
  open,
  closeModal,
  saveAndClose,
  billingAccount,
}: EditBillingAccountModalProps) => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [billingAccountToEdit, setBillingAccountToEdit] = useState<BillingAccount>(billingAccount);
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    setBillingAccountToEdit(billingAccount);
  }, [billingAccount]);

  const schema = yup.object({
    reason: yup
      .string()
      .min(3, 'The reason must be a minimum of 3 characters')
      .max(128, 'The company name can max be 30 characters')
      .required(),
    billingModel: yup
      .mixed<BillingModel>()
      .oneOf(Object.values(BillingModel))
      .required(),
    billingProcessor: yup
      .mixed<BillingProcessor>()
      .oneOf(Object.values(BillingProcessor))
      .required(),
    paymentMethod: yup
      .mixed<PaymentMethod>()
      .oneOf(Object.values(PaymentMethod))
      .required(),
    pricingPlan: yup
      .mixed<PricingPlanName>()
      .oneOf(Object.values(PricingPlanName))
      .required(),
  });

  const validate = (data: BillingAccount) => {
    return validateYup(schema, data, false);
  };

  const handleClose = () => {
    setErrorMessage(null);
    closeModal();
  };

  const handleSubmit = async () => {
    const errors = validate(billingAccountToEdit);
    if (errors) {
      setValidation(errors);
      return false;
    }
    setErrorMessage(null);

    setLoading(true);
    try {
      const editedBillingAccount = await BillingAccountsResource.update(
        billingAccount.organizationId,
        billingAccount.id,
        billingAccountToEdit
      );
      setLoading(false);
      saveAndClose(editedBillingAccount);
    } catch (err) {
      setErrorMessage(err.toString());
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (data: BillingAccount) => {
    setValidation(null);
    setBillingAccountToEdit(data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      modalProps={{ 'data-testid': 'edit-billing-account-modal' }}
    >
      <Dialog.Header>Edit Billing Account</Dialog.Header>
      <Dialog.Description>
        <div className="n-body-large">Edit Billing Account {`"${billingAccount?.name}"`}.</div>
      </Dialog.Description>
      <Dialog.Content data-testid="edit-billing-account-content">
        <Form onSubmit={handleSubmit} id="edit-billing-account-form">
          <BillingAccountEditFormFields
            billingAccount={billingAccountToEdit}
            onChange={handleChange}
            validation={validation}
          />
        </Form>
        {errorMessage && (
          <Alert
            className="tw-mt-2"
            type="danger"
            data-testid="edit-billing-account-error-message"
            description={`There was an error editing the Billing Account: ${errorMessage}`}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Button
          color="neutral"
          fill="outlined"
          disabled={loading}
          onClick={handleClose}
          data-testid="discard-edit-billing-account-button"
        >
          Discard
        </Button>
        <Button
          loading={loading}
          type="submit"
          form="edit-billing-account-form"
          data-testid="edit-billing-account-submit-button"
        >
          Submit
        </Button>
      </Dialog.Actions>
    </Dialog>
  );
};

export default EditBillingAccountModal;
