import {
  ArrowDownIconOutline,
  ArrowDownTrayIconOutline,
  ArrowLeftIconOutline,
  ArrowLeftOnRectangleIconOutline,
  ArrowRightOnRectangleIconOutline,
  ArrowTopRightOnSquareIconOutline,
  ArrowUturnLeftIconOutline,
  ArrowUpIconOutline,
  ArrowUpRightIconOutline,
  CalendarIconOutline,
  ChevronDownIconOutline,
  CheckCircleIconSolid,
  CheckIconOutline,
  ChevronLeftIconOutline,
  ChevronRightIconOutline,
  ClockIconOutline,
  ClipboardDocumentCheckIconOutline,
  ShareIconOutline,
  ClipboardIconOutline,
  Cog6ToothIconOutline,
  Cog8ToothIconOutline,
  EllipsisHorizontalIconOutline,
  EnvelopeIconOutline,
  ExpandIcon,
  InformationCircleIconOutline,
  InformationCircleIconSolid,
  MegaphoneIconOutline,
  PauseIconOutline,
  PencilIconOutline,
  PencilSquareIconOutline,
  PlayIconOutline,
  PlusIconOutline,
  QuestionMarkCircleIconOutline,
  ShieldCheckIconOutline,
  ShieldExclamationIconOutline,
  ShrinkIcon,
  StopCircleIconOutline,
  TrashIconOutline,
  XMarkIconOutline,
  CommandLineIconOutline,
  FunnelIconOutline,
  ArrowRightIconOutline,
} from '@neo4j-ndl/react/icons';

import React from 'react';
import './icon.css';
import classnames from 'classnames';

import neoWorld from './neo-world.svg';
import neo4jAuraLegacyLogo from './aura-legacy.svg';
import neo4jAuraLogo from './aura-db.svg';
import neo4jAuraEnterpriseLogo from './aura-db-ent.svg';
import neo4jAuraDSLogo from './aura-ds.svg';
import neo4jAuraDSEnterpriseLogo from './aura-ds-ent.svg';
import neo4jAuraColoredLogo from './aura-db-colored.svg';
import neo4jAuraEnterpriseColoredLogo from './aura-db-ent-colored.svg';
import neo4jAuraDSColoredLogo from './aura-ds-colored.svg';
import neo4jAuraDSEnterpriseColoredLogo from './aura-ds-ent-colored.svg';
import bloomAppIcon from './bloom-app.svg';
import browserAppIcon from './browser-app.svg';
import dataImporterAppIcon from './data-importer-app.svg';
import gcp from './gcp.svg';
import azure from './azure.svg';
import aws from './aws.svg';
import awsDark from './aws-dark.svg';
import cmkKey from './cmk-key.svg';

const consoleIcons = {
  'neo4j-logo': neoWorld,
  logo: neo4jAuraLogo,
  'logo-enterprise': neo4jAuraEnterpriseLogo,
  'logo-ds': neo4jAuraDSLogo,
  'logo-dsenterprise': neo4jAuraDSEnterpriseLogo,
  'logo-colored': neo4jAuraColoredLogo,
  'logo-enterprise-colored': neo4jAuraEnterpriseColoredLogo,
  'logo-ds-colored': neo4jAuraDSColoredLogo,
  'logo-dsenterprise-colored': neo4jAuraDSEnterpriseColoredLogo,
  'logo-legacy': neo4jAuraLegacyLogo,
  'bloom-app': bloomAppIcon,
  'browser-app': browserAppIcon,
  'data-importer-app': dataImporterAppIcon,
  gcp,
  aws,
  azure,
  'aws-dark': awsDark,
  'cmk-key': cmkKey,
};

const ndlIcons = {
  ArrowDownIconOutline,
  ArrowDownTrayIconOutline,
  ArrowLeftIconOutline,
  ArrowLeftOnRectangleIconOutline,
  ArrowRightIconOutline,
  ArrowRightOnRectangleIconOutline,
  ArrowTopRightOnSquareIconOutline,
  ArrowUturnLeftIconOutline,
  ArrowUpIconOutline,
  ArrowUpRightIconOutline,
  CalendarIconOutline,
  ChevronDownIconOutline,
  CheckCircleIconSolid,
  CheckIconOutline,
  ChevronLeftIconOutline,
  ChevronRightIconOutline,
  ClockIconOutline,
  ShareIconOutline,
  ClipboardDocumentCheckIconOutline,
  ClipboardIconOutline,
  Cog6ToothIconOutline,
  Cog8ToothIconOutline,
  EllipsisHorizontalIconOutline,
  EnvelopeIconOutline,
  ExpandIcon,
  FunnelIconOutline,
  InformationCircleIconOutline,
  InformationCircleIconSolid,
  MegaphoneIconOutline,
  PauseIconOutline,
  PencilIconOutline,
  PencilSquareIconOutline,
  PlayIconOutline,
  PlusIconOutline,
  QuestionMarkCircleIconOutline,
  ShieldCheckIconOutline,
  ShieldExclamationIconOutline,
  ShrinkIcon,
  StopCircleIconOutline,
  TrashIconOutline,
  XMarkIconOutline,
  CommandLineIconOutline,
};

type ConsoleIconNames = keyof typeof consoleIcons;

type NdlIconNames = keyof typeof ndlIcons;

export type IconNames = ConsoleIconNames | NdlIconNames;

const isConsoleIcon = (props: IconProps): props is ConsoleIconProps => {
  return props.name in consoleIcons;
};

const isNdlIcon = (props: IconProps): props is NdlIconProps => {
  return props.name in ndlIcons;
};

interface BaseProps extends Record<string, any> {
  title?: string;
  'aria-label'?: string;
  size?: 'xx-small' | 'x-small' | 'small' | 'regular' | 'large';
  className?: string;
  width?: string;
  height?: string;
  style?: Record<string, string>;
}

interface ConsoleIconProps extends BaseProps {
  name: ConsoleIconNames;
}

interface NdlIconProps extends BaseProps {
  name: NdlIconNames;
}

// Gets TS to behave and understand that 'name' can be neo4j icon name
export type IconProps = Omit<BaseProps, 'name'> & {
  name: IconNames;
};

const ConsoleIcon = (props: ConsoleIconProps) => {
  const { name, title, size, className = '', 'aria-label': ariaLabel, ...rest } = props;
  const Neo4jIcon = consoleIcons[name]; // Svg
  const iconTitle = title;

  const classes = classnames('console-icon', size, className);

  return (
    <Neo4jIcon
      title={iconTitle}
      aria-label={ariaLabel || iconTitle}
      className={classes}
      {...rest}
    />
  );
};

const NdlIcon = (props: NdlIconProps) => {
  const { name, title, size, className = '', style = {}, 'aria-label': ariaLabel, ...rest } = props;
  const Component = ndlIcons[name];
  const iconTitle = title;
  const styles = { ...style };

  const classes = classnames('ndl-icon', size, className);
  return (
    <Component
      title={iconTitle}
      aria-label={ariaLabel}
      className={classes}
      style={styles}
      {...rest}
    />
  );
};

const Icon = (props: IconProps) => {
  if (isConsoleIcon(props)) {
    return <ConsoleIcon {...props} />;
  }

  if (isNdlIcon(props)) {
    return <NdlIcon {...props} />;
  }
  return <></>;
};

export default Icon;
