import React, { SyntheticEvent, useMemo, useState, useEffect } from 'react';
import Actions from 'actions';
import { InviteUserToEnterpriseTenantModal } from './invite-user-to-enterprise-modal';
import classNames from 'classnames';
import { RemoveUserFromTenantModal } from './remove-user-from-tenant-modal';
import { ResetUserAuthenticationModal } from './reset-user-authentication-modal';
import { TerminateUserModal } from './terminate-user-modal';
import { UserRolesModal } from './users-roles-modal';
import { UserPendingInvitesModal } from './users-invites-modal';
import { TenantUser, RoleName, User, getTenantRoleOptions } from 'types/user';
import { Tenant, TenantSummary } from 'entities/tenant';
import TenantsResource from 'remote/resources/tenants';
import EditUserModal from 'components/pages/user-management/modals/edit-user-modal';
import Icon from 'ui/icons';
import {
  createColumnHelper,
  Dialog,
  IconButton,
  Link,
  Menu,
  MenuItem,
  useDefaultTable,
  TextInput,
  Label,
  DataGrid,
  StickyActionsBodyRowCells,
  LoadingSpinner,
} from 'foundation';
import './users-table.css';
import { UpdateDefaultTenantModal } from './update-default-tenant-modal';
import { UpdateEmailMFAModal } from './email-mfa-modal';
import globals from 'browser/globals';

interface Props {
  users: User[];
  tenant?: Tenant;
  displaySearch?: boolean;
  usersRole?: { [key: string]: string };
  onRefetchData?: () => void;
  projectDetailsOnly?: boolean;
}

interface Headers extends User {
  Actions: React.ReactNode;
  Role?: string;
}

const helper = createColumnHelper<Headers>();

const UserTableRow = ({ row }) => {
  const { Terminated } = row.original as Headers;
  const classes = classNames(
    'sticky-action-row tw-flex tw-flex-row tw-justify-between tw-border-t tw-min-h-[56px]',
    {
      'console-terminated-user': Terminated,
    }
  );

  return (
    <div className={classes} key={`sticky-actions-row-${row.id}`} role="row">
      <StickyActionsBodyRowCells row={row} />
    </div>
  );
};

type TenantsMap = {
  [tenantId: string]: TenantSummary;
};

export const UsersTable = ({
  users,
  tenant,
  displaySearch,
  usersRole = {},
  onRefetchData,
  projectDetailsOnly,
}: Props) => {
  const hasUserMatches = (users ?? []).length > 0;

  const [globalFilter, setGlobalFilter] = useState('');

  // Tenant Membership editing state if applicable
  const [editedMember, setEditedMember] = useState<TenantUser>(null);
  const [editInProgress, setEditInProgress] = useState<boolean>(false);
  const [editErrorMessage, setEditErrorMessage] = useState<string | null>(null);

  const [userTenantsMap, setUserTenantsMap] = useState<TenantsMap>({});
  const [isLoadingTenants, setLoadingTenants] = useState<boolean>(hasUserMatches);

  useEffect(() => {
    if (hasUserMatches) {
      setLoadingTenants(true);
      Promise.all(users.map(u => TenantsResource.listByUserId(u.UserId)))
        .then((allUserTenants: TenantSummary[][]) => {
          const tenantsMap: TenantsMap = {};

          for (let userTenants of allUserTenants) {
            for (let tenant of userTenants) {
              tenantsMap[tenant.id] = tenant;
            }
          }

          setUserTenantsMap(tenantsMap);
        })
        .finally(() => setLoadingTenants(false));
    }
  }, [users]);

  const handleMemberRoleChange = (member: TenantUser, newRoleName: RoleName) => {
    setEditInProgress(true);
    setEditErrorMessage(null);

    Actions.namespaces
      .changeMemberRole(member, newRoleName)
      .then(() => {
        setEditedMember(null);
        onRefetchData?.();
      })
      .catch(ex => {
        if (ex.response.status === 403) {
          setEditErrorMessage('You do not have permission to perform that action.');
        } else {
          setEditErrorMessage(ex.responseMessage);
        }
      })
      .finally(() => {
        setEditInProgress(false);
      });
  };

  const columns = useMemo(
    () => [
      helper.accessor('Role', {
        cell: cx => {
          const roleName = cx.getValue();

          // We construct this data as best as we can,
          // but most of it isn't actually used anyway
          const member: TenantUser = {
            NamespaceId: tenant.id,
            UserId: cx.row.original.UserId,
            Email: cx.row.original.Email,
            Roles: [
              {
                // Don't have it; don't need it
                RoleId: null,
                Name: roleName as RoleName,
                UserId: cx.row.original.UserId,
                NamespaceId: tenant.id,
              },
            ],
          };

          let roleLabel: string;

          switch (roleName) {
            case RoleName.TENANT_ADMIN:
              roleLabel = 'Admin';
              break;
            case RoleName.TENANT_MEMBER:
              roleLabel = 'Member';
              break;
            case RoleName.TENANT_VIEWER:
              roleLabel = 'Viewer';
              break;
            case RoleName.TENANT_METRICS_INTEGRATION_READER:
              roleLabel = 'Metrics Integration Reader';
              break;
            default:
              roleLabel = roleName;
              break;
          }

          return (
            <div className="tw-flex tw-flew-row tw-w-full tw-items-center">
              <Label fill="outlined" color="info" className="console-role-label">
                <div className="tw-truncate">{roleLabel}</div>
              </Label>
              <IconButton
                title="Edit Member"
                aria-label="Edit Member"
                iconName="PencilSquareIconOutline"
                onClick={() => setEditedMember(member)}
                clean
              />
            </div>
          );
        },
        enableHiding: !tenant,
      }),
      ...(projectDetailsOnly
        ? []
        : [
            helper.accessor('Email', {
              cell: cx => (
                <div className="tw-truncate">
                  <Link
                    href={`${globals.window.location.origin}/#admin/users/${cx.getValue()}`}
                    title={`User details page for ${cx.getValue()}`}
                  >
                    {cx.getValue()}
                  </Link>
                </div>
              ),
            }),
            helper.accessor('UserId', {
              cell: cx => (
                <div className="tw-truncate">
                  <Link
                    href={`${globals.window.location.origin}/#admin/users/${cx.getValue()}`}
                    title={`User details page for ${cx.getValue()}`}
                  >
                    {cx.getValue()}
                  </Link>
                </div>
              ),
            }),
          ]),
      helper.accessor('Namespaces', {
        header: 'Projects',
        cell: cx => {
          const tenantIds: string[] = cx.getValue();
          return (
            <div className="tw-w-full">
              {tenantIds?.map((id, index) => {
                const twMargin = index > 0 ? 'tw-mt-4' : '';
                if (isLoadingTenants) {
                  return <LoadingSpinner key={index} className={`!tw-h-[20.5px] ${twMargin}`} />;
                } else {
                  const tenantDetails: TenantSummary | undefined = userTenantsMap[id];
                  return (
                    <div key={index} className={`tw-truncate ${twMargin}`}>
                      <Link
                        href={`#admin/tenants/${id}/users`}
                        title={`${tenantDetails?.name} (${id})`}
                      >
                        {tenantDetails?.name} (<code>{id}</code>)
                      </Link>
                    </div>
                  );
                }
              })}
            </div>
          );
        },
      }),
      helper.accessor('Namespaces', {
        header: 'Suspended',
        cell: cx => {
          const tenantIds: string[] = cx.getValue();
          return (
            <div className="tw-w-full">
              {tenantIds?.map((id, index) => {
                const twMargin = index > 0 ? 'tw-mt-4' : '';
                if (isLoadingTenants) {
                  return <LoadingSpinner key={index} className={`!tw-h-[20.5px] ${twMargin}`} />;
                } else {
                  const tenantDetails: TenantSummary | undefined = userTenantsMap[id];
                  return tenantDetails?.suspended ? (
                    <div key={index} className={`tw-truncate ${twMargin}`}>
                      true
                    </div>
                  ) : (
                    <div key={index} className={`tw-truncate ${twMargin}`}>
                      false
                    </div>
                  );
                }
              })}
            </div>
          );
        },
      }),

      helper.accessor('DefaultNamespace', {
        header: 'Default Project',
        cell: cell => {
          const id = cell.getValue();
          if (isLoadingTenants) {
            return <LoadingSpinner className="!tw-h-[20.5px]" />;
          } else {
            const tenantDetails: TenantSummary | undefined = userTenantsMap[id];
            return (
              <div className="tw-w-full">
                <div className="tw-truncate">
                  <Link
                    href={`#admin/tenants/${id}/users`}
                    title={`${tenantDetails?.name} (${id})`}
                  >
                    {tenantDetails?.name} (<code>{id}</code>)
                  </Link>
                </div>
              </div>
            );
          }
        },
      }),
      helper.accessor('Actions', {
        id: 'actions',
        cell: cell => cell.getValue(),
        enableSorting: false,
        enableResizing: false,
        header: null,
        size: 90,
        meta: {
          isStickyAction: true,
        },
      }),
    ],
    [isLoadingTenants]
  );
  const data: Headers[] = useMemo(
    () =>
      users.map(user => ({
        ...user,
        Actions: <UserActions user={user} tenant={tenant} />,
        Role: usersRole[user.UserId],
      })),
    [users]
  );

  const table = useDefaultTable({
    columns,
    data,
    state: {
      globalFilter,
      columnVisibility: { Role: !!tenant },
    },
    enableHiding: true,
    onGlobalFilterChange: setGlobalFilter,
  });

  return (
    <div className="tw-flex tw-flex-col tw-gap-1">
      {displaySearch && (
        <div>
          <TextInput
            aria-label="Table search"
            placeholder="Search"
            value={globalFilter}
            onChange={value => setGlobalFilter(value.target.value)}
          />
        </div>
      )}
      {editedMember && (
        <EditUserModal
          open={editedMember !== null}
          user={editedMember}
          onClose={() => {
            setEditedMember(null);
            setEditErrorMessage(null);
            setEditInProgress(false);
          }}
          onEdit={handleMemberRoleChange}
          editInProgress={editInProgress}
          errorMessage={editErrorMessage}
          roleOptions={tenant ? getTenantRoleOptions(tenant) : []}
        />
      )}
      <DataGrid
        tableInstance={table}
        isResizable={false}
        styling={{ headerStyle: 'clean' }}
        components={{
          BodyRow: UserTableRow,
          ...(displaySearch ? {} : { Navigation: null }),
        }}
      />
    </div>
  );
};

interface UserActionsProps {
  user: User;
  tenant?: Tenant;
}

const UserActions = ({ user, tenant }: UserActionsProps) => {
  const [showDetails, setShowDetails] = useState(false);
  const [showInviteToEnterprise, setShowInviteToEnterprise] = useState(false);
  const [showRemoveUserFromTenant, setShowRemoveUserFromTenant] = useState(false);
  const [showResetUserAuthentication, setShowResetUserAuthentication] = useState(false);
  const [showUpdateEmailMfa, setShowUpdateEmailMfa] = useState(false);
  const [showUsersRoles, setShowUsersRoles] = useState(false);
  const [showUsersPendingInvites, setShowUsersPendingInvites] = useState(false);
  const [showTerminateUserModal, setShowTerminateUserModal] = useState(false);
  const [showDefaultTenantModal, setShowDefaultTenantModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    setAnchorEl(e.currentTarget);
  };
  const handleCloseMenu = () => setAnchorEl(null);
  const handleCancel = (onCancel: (boolean) => void) => {
    handleCloseMenu();
    onCancel(false);
  };

  return (
    <>
      <IconButton
        className="tw-ml-2 tw-center"
        onClick={handleOpenMenu}
        color="neutral"
        title="Additonal actions"
        aria-label="Additonal actions"
        data-testid="user-actions"
        iconName="EllipsisHorizontalIconOutline"
        clean
      />
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        anchorEl={anchorEl}
        data-testid="snapshot-extra-menu"
      >
        <Menu.Items>
          <MenuItem
            data-testid="set-default-tenant"
            onClick={() => setShowDefaultTenantModal(true)}
            name="Set default project"
            title="Set default project"
          />
          <MenuItem
            data-testid="invite-user-to-enterprise"
            onClick={() => setShowInviteToEnterprise(true)}
            name="Invite to VDC project"
            title="Invite to VDC project"
          />
          <MenuItem
            data-testid="remove-user-from-tenant"
            disabled={user.Namespaces.length < 2}
            onClick={() => setShowRemoveUserFromTenant(true)}
            name="Revoke access"
            title="Revoke access"
          />
          <MenuItem
            onClick={() => setShowResetUserAuthentication(true)}
            data-testid="reset-user-authentication"
            name="Reset user authentication"
            title="Reset user authentication"
          />
          <MenuItem
            data-testid="display-user-details"
            onClick={() => setShowDetails(true)}
            name="Display user details"
            title="Display user details"
          />
          <MenuItem
            data-testid="display-user-roles"
            onClick={() => setShowUsersRoles(true)}
            name="Roles"
            title="Roles"
          />
          <MenuItem
            data-testid="display-user-invites"
            onClick={() => setShowUsersPendingInvites(true)}
            name="Pending Invites"
            title="Pending Invites"
          />
          <MenuItem
            data-testid="toggle-email-mfa"
            onClick={() => setShowUpdateEmailMfa(true)}
            name="Update email MFA"
            title={`${
              [undefined, true].includes(user.EmailMFAEnabled) ? 'Disable' : 'Enable'
            } Email MFA`}
          />
          {user.Terminated ? (
            <MenuItem
              data-testid="unterminate-user"
              onClick={() => setShowTerminateUserModal(true)}
              name="Unterminate user"
              title="Unterminate user"
            />
          ) : (
            <MenuItem
              data-testid="terminate-user"
              onClick={() => setShowTerminateUserModal(true)}
              name="Terminate user"
              title="Terminate user"
            />
          )}
          <MenuItem
            title="User Logs (StackDriver)"
            name="User Logs (StackDriver)"
            as="a"
            href={`https://console.cloud.google.com/logs/query;query=labels.%22k8s-pod%2Fapp%22%3D%22console-api%22%0Aseverity%3E%3D%22DEBUG%22%0AjsonPayload.message:%22${user.UserId}%22%0A-jsonPayload.method%3D%22GET%22%0A-jsonPayload.message:%22is%20covered%20by%20READ%20%22;timeRange=P7D;summaryFields=jsonPayload%252Fdbid:false:32:beginning;cursorTimestamp=2023-06-08T16:25:48.311391129Z?project=neo4j-cloud`}
            target="_blank"
            rel="noopener noreferrer"
            icon={<Icon name="ArrowUpRightIconOutline" className="tw-h-4 tw-w-4 tw-mb-2" />}
          />
        </Menu.Items>
      </Menu>

      {showUpdateEmailMfa && (
        <UpdateEmailMFAModal user={user} onClose={() => handleCancel(setShowUpdateEmailMfa)} />
      )}

      {showUsersRoles && (
        <UserRolesModal
          open={showUsersRoles}
          user={user}
          onClose={() => handleCancel(setShowUsersRoles)}
        />
      )}
      {showUsersPendingInvites && (
        <UserPendingInvitesModal
          open={showUsersPendingInvites}
          user={user}
          onClose={() => handleCancel(setShowUsersPendingInvites)}
        />
      )}
      {showInviteToEnterprise && (
        <InviteUserToEnterpriseTenantModal
          email={user.Email}
          onClose={() => handleCancel(setShowInviteToEnterprise)}
        />
      )}
      {showRemoveUserFromTenant && (
        <RemoveUserFromTenantModal
          user={user}
          currentTenant={tenant?.id}
          open={showRemoveUserFromTenant}
          onClose={() => handleCancel(setShowRemoveUserFromTenant)}
        />
      )}
      {showResetUserAuthentication && (
        <ResetUserAuthenticationModal
          user={user}
          open={showResetUserAuthentication}
          onClose={() => handleCancel(setShowResetUserAuthentication)}
        />
      )}
      {showTerminateUserModal && (
        <TerminateUserModal
          user={user}
          open={showTerminateUserModal}
          onClose={() => handleCancel(setShowTerminateUserModal)}
        />
      )}
      {showDefaultTenantModal && (
        <UpdateDefaultTenantModal
          user={user}
          open={showDefaultTenantModal}
          onClose={() => handleCancel(setShowDefaultTenantModal)}
        />
      )}

      {showDetails && (
        <Dialog open={showDetails} onClose={() => handleCancel(setShowDetails)}>
          <pre data-testid="user-details-modal-content">{JSON.stringify(user, null, '  ')}</pre>
        </Dialog>
      )}
    </>
  );
};
