export const MAXIMUM_DAYS = 365;

export enum FilterReportsSelection {
  START_OF_MONTH = 'startOfMonth',
  LAST_24_HOURS = 'last24Hours',
  LAST_7_DAYS = 'last7Days',
  LAST_30_DAYS = 'last30Days',
  LAST_90_DAYS = 'last90Days',
  LAST_YEAR = 'lastYear',
  CUSTOM_RANGE = 'customRange',
}

export const DATE_SELECTION_OPTIONS = [
  { label: 'Current month', value: FilterReportsSelection.START_OF_MONTH },
  { label: 'Last 24 hours', value: FilterReportsSelection.LAST_24_HOURS, noDays: 1 },
  { label: 'Last 7 days', value: FilterReportsSelection.LAST_7_DAYS, noDays: 7 },
  { label: 'Last 30 days', value: FilterReportsSelection.LAST_30_DAYS, noDays: 30 },
  { label: 'Last 90 days', value: FilterReportsSelection.LAST_90_DAYS, noDays: 90 },
  { label: 'Last year', value: FilterReportsSelection.LAST_YEAR, noDays: 365 },
  { label: 'Custom range', value: FilterReportsSelection.CUSTOM_RANGE },
];
