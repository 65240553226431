import React, { useState } from 'react';
import { FormInput, Alert, Button, Form, Checkbox } from 'foundation';
import { InternalCustomEndpointDetails } from 'types/custom-endpoints';
import customEndpointResources from 'remote/resources/custom-endpoints';
import { CustomEndpointsTable } from './custom-endpoints-table';
import * as yup from 'yup';
import { Validation, validateYup } from 'utils/validation';

const schema = yup.object({
  url: yup
    .string()
    .required()
    .matches(
      /^[a-z0-9+]+:\/\/[a-z0-9-]+-[a-z0-9]+-[a-z0-9]+\.endpoints\.neo4j\.io$/,
      'Invalid format'
    )
    .label('URL'),
});

const SearchByURLForm = ({ ...rest }) => {
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [includeDeleted, setIncludeDeleted] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [customEndpoints, setCustomEndpoints] = useState<InternalCustomEndpointDetails[]>([]);
  const [validationError, setValidationError] = useState<Validation<{ url: string } | null>>(null);

  const handleQueryChange = ({ target: { value } }) => {
    setQuery(value);
    setValidationError(null);
  };

  const handleIncludeDeletedChange = ({ target: { checked } }) => setIncludeDeleted(checked);

  const validate = (data: { url: string }) => {
    return validateYup(schema, data);
  };

  const handleSearchSubmit = async () => {
    if (!query) return;
    setLoading(true);
    setCustomEndpoints([]);
    setError(null);

    const errors = validate({ url: query.trim().toLowerCase() });
    if (errors) {
      setValidationError(errors);
      setLoading(false);
      return;
    }

    try {
      const result = await customEndpointResources.internalListByQuery({
        url: query.trim().toLowerCase(),
        includeDeleted: includeDeleted,
      });
      setCustomEndpoints(result);
    } catch (err) {
      setError(String(err));
    }
    setLoading(false);
  };

  return (
    <div {...rest}>
      <p className="tw-mb-2">
        This form enables you to find a custom endpoint by its URL, such as{' '}
        <code>&apos;neo4j+s//example-abcd-1234.endpoints.neo4j.io&apos;</code>.
      </p>
      <p className="tw-mb-2">Only exact URL matches can be found.</p>

      <Form onSubmit={handleSearchSubmit}>
        <FormInput
          fluid
          value={query}
          onChange={handleQueryChange}
          label="URL"
          data-testid="lookup-custom-endpoint-by-url"
          name="url"
          autoComplete="url"
          helpText="The complete URL of the custom endpoint"
          errorText={validationError?.url?.message}
        />
        <div className="tw-flex tw-flex-row tw-gap-5 tw-mt-8 tw-items-center">
          <Checkbox
            label={'Include deleted entities'}
            checked={includeDeleted}
            onChange={handleIncludeDeletedChange}
          />
          <Button
            type="submit"
            color="primary"
            loading={loading}
            disabled={!query || !!validationError}
            data-testid="lookup-custom-endpoint-by-url-button"
          >
            Search
          </Button>
        </div>

        {error && (
          <Alert
            type="danger"
            data-testid="lookup-custom-endpoint-error-message"
            className="tw-mt-8"
          >
            {error}
          </Alert>
        )}
      </Form>

      {customEndpoints.length > 0 && (
        <CustomEndpointsTable
          customEndpoints={customEndpoints}
          data-testid="search-custom-endpoints-result-table"
        />
      )}
    </div>
  );
};

export default SearchByURLForm;
