import React from 'react';
import TotalBillingUsage from 'application/usage/report';
import { Tenant } from 'entities/tenant';
import { AdminConsumptionReport } from 'components/application/consumption';

interface Props {
  tenant: Tenant;
  className?: string;
}

export const UsagePane = ({ tenant, className }: Props) => {
  const { capabilities } = tenant;
  const orbConsumptionReportingEnabled = capabilities.orb_consumption_reporting;

  if (orbConsumptionReportingEnabled) {
    return (
      <div className={className}>
        <AdminConsumptionReport tenant={tenant} />
      </div>
    );
  }
  return (
    <div className={className}>
      <TotalBillingUsage tenant={tenant} />
    </div>
  );
};
