import ConsumptionResource from 'remote/resources/consumption';
import {
  TransformedConsumptionBreakdown,
  TransformedDiscount,
  TransformedConsumption,
  Filter,
  DateFilter,
  Pagination,
  Sorting,
} from 'types/consumption';

const fetch = (
  tenantId: string,
  filters?: Filter[] | DateFilter[],
  pagination?: Pagination,
  sorting?: Sorting,
  searchFilter?: string
): Promise<TransformedConsumption> =>
  ConsumptionResource.get(tenantId, filters, pagination, sorting, searchFilter).then(
    ({
      total_cost,
      currency,
      total_breakdown_count,
      breakdown,
      usage_period_end,
      usage_period_start,
      discount,
    }) => {
      let transformedDiscount: TransformedDiscount;

      if (discount) {
        transformedDiscount = {
          promotionName: discount.promotion_name,
          voucherCode: discount.voucher_code,
          dollarAmountOff: discount.dollar_amount_off,
        };
      }

      let transformedBreakdown: TransformedConsumptionBreakdown;

      if (breakdown) {
        transformedBreakdown = breakdown.map(dbUsage => ({
          dbid: dbUsage.dbid,
          name: dbUsage.name,
          tier: dbUsage.tier,
          productName: dbUsage.product_name,
          pricingPlan: dbUsage.pricing_plan,
          usage: dbUsage.usage,
          cost: dbUsage.cost,
          currency: dbUsage.currency,
          unitOfMeasure: dbUsage.unit_of_measure,
          unitCostInDollars: dbUsage.unit_cost_in_dollars,
          billingStatus: dbUsage.billing_status,
        }));
      }

      return {
        totalCost: total_cost,
        currency: currency,
        totalBreakdownCount: total_breakdown_count,
        breakdown: transformedBreakdown,
        usagePeriodEnd: usage_period_end,
        usagePeriodStart: usage_period_start,
        discount: transformedDiscount,
      };
    }
  );

export default { fetch };
