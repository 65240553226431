import { User } from 'types/user';
import { Dialog } from 'foundation';
import React, { useMemo, useState, useEffect } from 'react';
import classNames from 'classnames';
import InvitesResource from 'remote/resources/invites';
import {
  createColumnHelper,
  useDefaultTable,
  DataGrid,
  StickyActionsBodyRowCells,
} from 'foundation';
import './users-table.css';
import { InviteDetails } from 'types/invite';

interface Props {
  user: User;
  open: boolean;
  onClose: () => void;
}

interface Headers extends InviteDetails {
  Invites?: InviteDetails[];
  Status: string;
}

const helper = createColumnHelper<Headers>();

const UserTableRow = ({ row }) => {
  const classes = classNames(
    'sticky-action-row tw-flex tw-flex-row tw-justify-between tw-border-t tw-min-h-[56px]'
  );

  return (
    <div className={classes} key={`sticky-actions-row-${row.id}`} role="row">
      <StickyActionsBodyRowCells row={row} />
    </div>
  );
};

export const UserPendingInvitesModal = ({ open, onClose, user }: Props) => {
  const [userInvites, setUserInvites] = useState<InviteDetails[]>([]);
  const [isLoadingInvites, setLoadingInvites] = useState<boolean>(true);

  useEffect(() => {
    setLoadingInvites(true);
    const fetchUserInvites = async () => {
      const invites = await InvitesResource.getByUser(user.UserId);
      setUserInvites(invites);
    };
    fetchUserInvites();
    setLoadingInvites(false);
  }, [user]);

  useEffect(() => {
    if (!open) {
      return;
    }
  }, [open]);

  const columns = useMemo(
    () => [
      helper.accessor('InviteId', {
        cell: cx => <div className="tw-truncate">{cx.getValue()}</div>,
      }),
      helper.accessor('NamespaceId', {
        cell: cx => <div className="tw-truncate">{cx.getValue()}</div>,
      }),
      helper.accessor('Status', {
        cell: () => <div className="tw-truncate">{'Pending'}</div>,
      }),
    ],
    [isLoadingInvites]
  );

  const data: Headers[] = useMemo(
    () =>
      userInvites?.map(invite => ({
        ...invite,
        Status: 'Pending',
      })),
    [userInvites]
  );

  const table = useDefaultTable({
    columns,
    data,
    enableHiding: true,
  });

  return (
    <Dialog open={open} onClose={onClose} size="large" modalProps={{ style: { minHeight: 500 } }}>
      <Dialog.Header>{user.Email} Invites</Dialog.Header>
      <div data-testid="test-users-invites-list">
        <DataGrid
          tableInstance={table}
          isResizable={false}
          styling={{ headerStyle: 'clean' }}
          components={{
            BodyRow: UserTableRow,
          }}
        />
      </div>
    </Dialog>
  );
};
