import { CloudProvider, Tier } from 'entities/database';
import { ProviderConfigs } from 'entities/tenant';

const neo5regex = /^(5$|5\.[0-9])/;
const neo4regex = /^(4$|4\.[0-9])/;
const neo3regex = /^(3$|3\.[0-9])/;

const getNeo4jVersionText = (input?: string, customerFacing = true): string => {
  if (!input) {
    return '';
  }
  if (neo3regex.exec(input)) {
    return '3.5';
  }
  if (neo4regex.exec(input)) {
    return '4';
  }
  if (neo5regex.exec(input)) {
    return customerFacing ? 'Latest' : '5';
  }
  const match = input.match(/^\d{4}\.\d{2}/);
  return match ? match[0] : '';
};

export const needsMigration = (dbVersion: string): boolean => {
  return !!neo4regex.exec(dbVersion) || !!neo3regex.exec(dbVersion);
};

export const getAvailableVersions = (
  providerConfigs: ProviderConfigs,
  cloudProvider: CloudProvider,
  tier: Tier
) => {
  return providerConfigs[cloudProvider].tiers[tier].versions.sort((a, b) => b.localeCompare(a));
};

export const getDefaultVersion = (
  providerConfigs: ProviderConfigs,
  cloudProvider: CloudProvider,
  tier: Tier
) => {
  return providerConfigs[cloudProvider].tiers[tier].default_version;
};

export { getNeo4jVersionText };
