import React, { SyntheticEvent, useState } from 'react';
import track, { useTracking } from 'react-tracking';

import Actions from 'actions';
import DestroyDatabaseModal from './modal';
import { isDeletable, Database, Tier, DatabaseStatus } from 'entities/database';
import { useDatabaseState, usePermissions, useSession } from 'store';
import { Action } from 'types/user';

export interface DeleteDatabaseChildProps {
  onClick: (e: SyntheticEvent) => void;
  disabled: boolean;
  loading: boolean;
  allowDelete: boolean;
}
export interface DeleteDatabaseProps {
  database: Database;
  children: (args: DeleteDatabaseChildProps) => JSX.Element;
}

const DeleteDatabase = ({ database, children }: DeleteDatabaseProps) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const tracking = useTracking();
  const { databases = [] } = useDatabaseState();
  const session = useSession();
  const { allow } = usePermissions();
  const allowDelete = allow(
    Action.DELETE,
    `namespaces/${session.currentTenant}/databases/${database.DbId}`
  );

  const gdsAvailable = session.tenant.capabilities.gds_sessions;

  const handleDeleteClicked = (e: SyntheticEvent) => {
    e.preventDefault();

    setOpen(true);
  };

  const handleDelete = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();

    const lastPaidInstance =
      session.tenant.requiresBilling &&
      instanceIsPaid(database) &&
      databases.filter(db => instanceIsPaid(db)).length === 1;

    tracking.trackEvent({
      action: 'destroy_db',
      properties: {
        dbid: database.DbId,
        last_paid_instance: lastPaidInstance ? 'true_delete' : 'false',
        proTrial: database.ProTrialEndTime ? 'proTrial_included' : 'proTrial_excluded',
      },
    });

    setLoading(true);

    Actions.databases.destroyDatabase(database.DbId).then(res => {
      setLoading(false);
      if (res && res.error) {
        setError(res.error);
      } else {
        setOpen(false);
        setError(null);
        Actions.navigate.push({ hash: '#databases' });
      }
    });
  };

  const handleCancel = () => {
    setLoading(false);
    setError(null);
    setOpen(false);
  };

  const disabled = loading || !isDeletable(database) || !allowDelete;

  return (
    <>
      {children({ onClick: handleDeleteClicked, loading, disabled, allowDelete })}
      <DestroyDatabaseModal
        open={open}
        database={database}
        loading={loading}
        onConfirm={handleDelete}
        onCancel={handleCancel}
        error={error}
        errorHeader="Unable to destroy database"
        gdsAvailable={gdsAvailable}
      />
    </>
  );
};

const instanceIsPaid = (instance: Database): boolean =>
  [Tier.PROFESSIONAL, Tier.GDS, Tier.MTE].includes(instance.Tier) &&
  instance.DatabaseStatus !== DatabaseStatus.DESTROYING &&
  !instance.ProTrialEndTime;

export default track({ page: 'delete-database' })(DeleteDatabase);
