import React, { useMemo } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
  CopyTextToClipBoardButton,
  IconButton,
  Link,
  StickyActionsDataGrid,
  Tip,
  useDefaultTable,
} from 'components/foundation';
import { InternalCustomEndpointDetails } from 'types/custom-endpoints';
import { customEndpointURI } from 'entities/custom-endpoints';
import Actions from 'actions';

const ActionRow = ({ endpoint }: { endpoint: InternalCustomEndpointDetails }) => {
  return (
    <Tip>
      <Tip.Trigger hasButtonWrapper>
        <IconButton
          clean
          aria-label={`Details for custom endpoint ${endpoint.id}`}
          onClick={() => Actions.navigate.push(`#admin/custom-endpoints/${endpoint.id}`)}
          data-testid={`custom-endpoints-details-${endpoint.id}`}
          iconName="ArrowRightIconOutline"
        />
      </Tip.Trigger>
      <Tip.Content isPortaled>Go to details page</Tip.Content>
    </Tip>
  );
};
interface Props {
  customEndpoints: InternalCustomEndpointDetails[];
  className?: string;
}

const headerHelper = createColumnHelper<InternalCustomEndpointDetails>();

export const CustomEndpointsTable = ({ customEndpoints }: Props) => {
  const columns = useMemo(
    () => [
      headerHelper.accessor('name', {
        header: 'URL',
        size: 450,
        cell: cx => {
          const url = customEndpointURI(cx.row.original);
          return (
            <div className="tw-flex tw-items-center tw-gap-1 tw-truncate">
              <div className="tw-text-ellipsis tw-overflow-hidden">{url}</div>
              <CopyTextToClipBoardButton text={url} />
            </div>
          );
        },
      }),
      headerHelper.accessor('id', {
        header: 'ID',
        size: 450,
        cell: cx => {
          const id = cx.getValue();
          return <Link href={`#admin/custom-endpoints/${id}`}>{id}</Link>;
        },
      }),
      headerHelper.accessor('dbId', {
        header: 'Assigned Instance',
        size: 250,
        cell: cx => {
          const dbId = cx.getValue();

          return <Link href={`#admin/databases/${dbId}`}>{dbId}</Link>;
        },
      }),
      headerHelper.accessor('sourceDbId', {
        header: 'Previously Assigned Instance',
        size: 300,
        cell: cx => {
          const sourceDbId = cx.getValue();

          return sourceDbId ? (
            <Link href={`#admin/databases/${sourceDbId}`}>{sourceDbId}</Link>
          ) : (
            ''
          );
        },
      }),
      headerHelper.accessor('namespaceId', {
        header: 'Project',
        size: 400,
        cell: cx => {
          const namespaceId = cx.getValue();

          return <Link href={`#admin/namespaces/${namespaceId}`}>{namespaceId}</Link>;
        },
      }),
      headerHelper.accessor('updatedAt', {
        header: 'Updated at',
        size: 300,
        cell: cx => {
          const updatedAt = cx.getValue();

          return updatedAt.toString();
        },
      }),
      headerHelper.accessor('deletedAt', {
        header: 'Deleted at',
        size: 300,
        cell: cx => {
          const deletedAt = cx.getValue();

          return deletedAt ? deletedAt.toString() : '';
        },
      }),
      headerHelper.display({ id: 'hidden_column', header: () => null, enableResizing: false }),
      headerHelper.display({
        id: 'actions',
        cell: cx => {
          const endpoint = cx.row.original;

          return <ActionRow endpoint={endpoint} />;
        },
        size: 80,
        enableResizing: false,

        meta: {
          isStickyAction: true,
        },
      }),
    ],
    []
  );

  const table = useDefaultTable({
    columns: columns,
    data: customEndpoints,
  });

  return (
    <div className="tw-mt-6 tw-w-full">
      <div className="tw-mt-12">
        <StickyActionsDataGrid tableInstance={table} />
      </div>
    </div>
  );
};
