import Actions from 'actions';
import Icon from 'components/ui/icons';
import { PlanType } from 'entities/tenant';
import { IconButton, Menu, MenuItem } from 'foundation';
import React, { SyntheticEvent, useState } from 'react';
import { useTracking } from 'react-tracking';
import { usePermissions, useSession } from 'store';
import { Action } from 'types/user';
import { PermissionTip } from 'application/permission-tip';
import { Database } from 'entities/database';

interface Props {
  onCreateFromBackupClick: (snapshotId: string) => any;
  snapshotId: string;
  fileName: string;
  planType: PlanType;
  database: Database;
}

export const SnapshotActions = ({
  onCreateFromBackupClick,
  planType,
  fileName,
  snapshotId,
  database,
}: Props) => {
  const session = useSession();
  const { allow } = usePermissions();
  const allowCreateInstancesFromSnapshot = allow(
    Action.CREATE,
    `namespaces/${session.currentTenant}/databases`
  );
  const allowDownloadSnapshot = allow(
    Action.DOWNLOAD,
    `namespaces/${session.currentTenant}/databases/${database.DbId}/snapshots/${snapshotId}`
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [active, setActive] = useState(false);
  const tracking = useTracking();

  const handleOpen = (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setActive(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setActive(false);
  };

  const onExportClick = () => {
    tracking.trackEvent({
      action: 'export_db_backup',
      properties: {
        event_label: 'clicked',
        ...(database.EncryptionKey && {
          encryptionKeyRef: database.EncryptionKey.encryptionKeyRef,
        }),
      },
    });
    Actions.downloadSnapshot(database.DbId, snapshotId, fileName);
  };

  return (
    <>
      <IconButton
        className="tw-ml-2 tw-center"
        onClick={handleOpen}
        color="neutral"
        title="Additional snapshot actions"
        aria-label="Additional snapshot actions"
        data-testid="snapshot-extra"
        iconName="EllipsisHorizontalIconOutline"
        active={active}
        clean
      />
      <Menu
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorEl={anchorEl}
        data-testid="snapshot-extra-menu"
      >
        <Menu.Items>
          <PermissionTip hasPermission={allowDownloadSnapshot}>
            <MenuItem
              title="Export"
              name="Export"
              icon={
                <Icon name="ArrowDownTrayIconOutline" title="Export" width="1em" height="1em" />
              }
              onClick={onExportClick}
              data-testid="export-snapshot"
              style={{ display: 'flex' }}
              nested={false}
              disabled={!allowDownloadSnapshot}
            />
          </PermissionTip>
          {[PlanType.ENTERPRISE, PlanType.SELF_SERVE].includes(planType) && (
            <PermissionTip hasPermission={allowCreateInstancesFromSnapshot}>
              <MenuItem
                title="Create instance from snapshot"
                name="Create instance from snapshot"
                data-testid="create-from-snapshot"
                onClick={() => onCreateFromBackupClick(snapshotId)}
                disabled={!allowCreateInstancesFromSnapshot}
                icon={
                  <Icon
                    name="PlusIconOutline"
                    width="1em"
                    height="1em"
                    title="Create instance from snapshot"
                  />
                }
              />
            </PermissionTip>
          )}
        </Menu.Items>
      </Menu>
    </>
  );
};
