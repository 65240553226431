import React, { useState, useEffect } from 'react';
import { gibibytesStringToInt, Tier } from 'entities/database';
import { Accordion, NewTabLink, Switch, Tip, Typography } from 'foundation';
import { CreateDatabaseFormData } from './form-data';

type FormProfessionalAdditionalSettingsProps = {
  data: CreateDatabaseFormData;
  onChange: (data: CreateDatabaseFormData) => void;
};

const FormProfessionalAdditionalSettings = ({
  data,
  onChange,
}: FormProfessionalAdditionalSettingsProps) => {
  const [openAdditionalSettings, setOpenAdditionalSettings] = useState(!!data.size);
  const handleGDSPluginChange = (value: boolean) => {
    onChange({ ...data, gdsPlugin: value });
  };
  useEffect(() => {
    setOpenAdditionalSettings(!!data.size);
  }, [data.size]);

  const handleVectorOptimizeChange = (value: boolean) => {
    onChange({ ...data, vectorOptimized: value });
  };

  return (
    <Accordion
      position="left"
      header={
        <div className="tw-flex tw-flex-row tw-w-full tw-justify-between tw-items-center">
          Additional settings <p className="tw-text-palette-neutral-text-weaker">(optional)</p>
        </div>
      }
      open={openAdditionalSettings}
      onOpen={() => setOpenAdditionalSettings(!openAdditionalSettings)}
      data-testid="accordion-additional-settings"
      disabled={!data.version}
    >
      <div className="tw-mb-4">
        {data.tier === Tier.PROFESSIONAL && (
          <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-pb-6">
            <div className="tw-flex tw-flex-col tw-gap-2">
              <Typography variant="subheading-medium">Graph Analytics</Typography>
              <Typography
                variant="body-medium"
                as="div"
                className="tw-text-palette-neutral-text-weak"
              >
                Enable Graph Analytics libraries on this instance. See{' '}
                <NewTabLink href="https://neo4j.com/docs/aura/graph-analytics/">
                  documentation
                </NewTabLink>{' '}
                for details.
              </Typography>
            </div>
            <div>
              <Tip
                isDisabled={
                  (data.size ? gibibytesStringToInt(data.size.memory) >= 4 : true) &&
                  data.version === '5'
                }
              >
                <Tip.Trigger>
                  <Switch
                    aria-label="Toggle to install GDS plugin on instance"
                    checked={data.gdsPlugin}
                    onChange={e => handleGDSPluginChange(e.target.checked)}
                    disabled={
                      (data.size ? gibibytesStringToInt(data.size.memory) < 4 : false) ||
                      data.version !== '5'
                    }
                  />
                </Tip.Trigger>
                <Tip.Content isPortaled={false}>
                  4GB memory is required for the GDS plugin configuration
                </Tip.Content>
              </Tip>
            </div>
          </div>
        )}

        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between tw-pb-6">
          <div className="tw-flex tw-flex-col tw-gap-2">
            <Typography variant="subheading-medium">Vector-optimized configuration</Typography>
            <Typography
              variant="body-medium"
              as="div"
              className="tw-text-palette-neutral-text-weak"
            >
              Optimize Neo4j for GraphRAG, GenAI, and semantic search applications that index and
              search vector embeddings. See{' '}
              <NewTabLink href="https://neo4j.com/docs/aura/managing-instances/instance-details/#aura-vector-optimization">
                documentation
              </NewTabLink>{' '}
              for details.
            </Typography>
          </div>
          <div>
            <Tip
              isDisabled={
                (data.size ? gibibytesStringToInt(data.size.memory) >= 4 : true) &&
                data.version === '5'
              }
            >
              <Tip.Trigger>
                <Switch
                  aria-label="Toggle on allocate vector index memory"
                  checked={data.vectorOptimized}
                  onChange={e => handleVectorOptimizeChange(e.target.checked)}
                  disabled={
                    (data.size ? gibibytesStringToInt(data.size.memory) < 4 : false) ||
                    data.version !== '5'
                  }
                />
              </Tip.Trigger>
              <Tip.Content isPortaled={false}>
                4GB memory is required for the vector index configuration
              </Tip.Content>
            </Tip>
          </div>
        </div>
      </div>
    </Accordion>
  );
};

export default FormProfessionalAdditionalSettings;
