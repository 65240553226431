import { KeyPairItem, KeyPairSection } from 'components/application/key-pair';
import LoadError from 'components/application/load-error';
import { Link, LoadingSpinner, Page } from 'components/foundation';
import React from 'react';
import { useInternalGet } from 'remote/resources/custom-endpoints';
import { InternalCustomEndpointDetails } from 'types/custom-endpoints';

export const CustomEndpointDetailsGuard = (
  component: React.ComponentType<{ customEndpoint: InternalCustomEndpointDetails }>
) => {
  return ({ customEndpointId }: { customEndpointId: string }) => {
    const customEndpointQuery = useInternalGet(customEndpointId); // eslint-disable-line

    if (customEndpointQuery.isLoading) {
      return <LoadingSpinner size="large" expand minHeight={300} />;
    }

    if (customEndpointQuery.isError) {
      return <LoadError />;
    }

    const customEndpoint = customEndpointQuery.data;

    const Component = component;
    return <Component customEndpoint={customEndpoint} />;
  };
};

export const CustomEndpointDetailsBase = ({
  customEndpoint,
}: {
  customEndpoint: InternalCustomEndpointDetails;
}) => {
  const logsLink = `https://console.cloud.google.com/logs/query;query=labels.%22k8s-pod%2Fapp%22%3D%22console-api%22%0AjsonPayload.custom_endpoint_id%3D%22${customEndpoint.id}%22;duration=P7D?project=neo4j-cloud&inv=1&invt=AbruoA&supportedpurview=project`;

  return (
    <Page fullWidth>
      <div>
        <h3>
          Custom Endpoint{' '}
          <i>
            {customEndpoint.name}-{customEndpoint.suffix}
          </i>
        </h3>
      </div>
      <div className="tw-mt-3">
        <KeyPairSection>
          <KeyPairItem label="ID">{customEndpoint.id}</KeyPairItem>
          <KeyPairItem label="Assigned instance">{customEndpoint.dbId}</KeyPairItem>
          {customEndpoint.sourceDbId && (
            <KeyPairItem label="Previously assigned instance">
              {customEndpoint.sourceDbId}
            </KeyPairItem>
          )}
          <KeyPairItem label="Project">{customEndpoint.namespaceId}</KeyPairItem>
          {customEndpoint.deletedAt ? (
            <KeyPairItem isCopyable={false} label="Deleted at">
              {customEndpoint.deletedAt.toString()}
            </KeyPairItem>
          ) : (
            <KeyPairItem isCopyable={false} label="Updated at">
              {customEndpoint.updatedAt.toString()}
            </KeyPairItem>
          )}
        </KeyPairSection>

        <p className="tw-my-2">
          View the Console API logs for this custom endpoint from the last 7 days{' '}
          <Link href={logsLink} newTab>
            here
          </Link>
          .
        </p>

        <p className="tw-my-2">Below is information retrieved from Traffic Config API.</p>

        <KeyPairSection>
          <KeyPairItem label="Fully qualified domain name">
            {customEndpoint.reportedFQDN}
          </KeyPairItem>
          <KeyPairItem label="Assigned instance">{customEndpoint.reportedDbid}</KeyPairItem>
          <KeyPairItem label="Orchestra">{customEndpoint.reportedOrchestra}</KeyPairItem>
          <KeyPairItem isCopyable={false} label="Status">
            {customEndpoint.reportedStatus}
          </KeyPairItem>
        </KeyPairSection>
      </div>
    </Page>
  );
};

export const CustomEndpointsDetails = CustomEndpointDetailsGuard(CustomEndpointDetailsBase);
